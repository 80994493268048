import React, { useEffect, useState } from "react";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // eslint-disable-next-line no-unused-vars
  Theme,
} from "@material-ui/core";
import DataTableRowComponent from "../../components/dataTableRowComponent";
import { updateSlot, getSlot } from "../../../plugins/requests";
import { Slot } from "../../../entity/slot.entity";
import CreateSlotDialog from "./CreateSlotDialog";
import { ShopSelection } from "../../components/shopSelection";
import { TextField } from "@material-ui/core";
import { useShops } from "../../../hooks/useShops";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    form: {
      "& > *": {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

const Slots: React.FC = () => {
  const classes = useStyles();

  const { shops, companies, selectedShop, setSelectedShop } = useShops();
  const [slots, setSlots] = useState([] as Slot[]);
  const [idleTimeStart, setIdleTimeStart] = useState(null as string | null);
  const [idleTimeEnd, setIdleTimeEnd] = useState(null as string | null);

  // edit flags
  const [editFocusIndex, setEditFocusIndex] = useState(null as null | number);

  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const [createSlotModalOpen, setCreateSlotModalOpen] = useState(false);

  const update = <K extends keyof Slot>(slot: Slot, key: K, value: Slot[K]) => {
    updateSlot(slot.company_id, slot.shop_id, { [key]: value })
      .then(updatedSlot => {
        setSlots(
          slots.map(existingSlot =>
            existingSlot.shop_id === updatedSlot.shop_id
              ? updatedSlot
              : existingSlot,
          ),
        );
        setDialogMessage("update success");
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
    setEditFocusIndex(null);
  };

  const selectShop = (shopId: string) => {
    const shop = shops.find(_shop => _shop.shop_id === shopId);
    if (!shop) return;
    const company = companies.find(
      _company => _company.company_id === shop.company_id,
    );
    if (!company) return;
    setSelectedShop(shop);
  };

  useEffect(() => {
    if (!selectedShop) return;
    setSlots([]);
    getSlot(selectedShop.company_id, selectedShop.shop_id).then(slot => {
      setSlots([slot]);
    });
  }, [selectedShop]);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        style={{ width: "70vw", marginBottom: "30px" }}
        onClick={() => setCreateSlotModalOpen(true)}
      >
        スロットを作成する
      </Button>

      <ShopSelection
        selectedShop={selectedShop}
        shops={shops}
        selectShop={selectShop}
      />

      <Paper>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">start</TableCell>
              <TableCell align="right">end</TableCell>
              <TableCell align="right">5分間の受付注文上限数</TableCell>
              <TableCell align="right">アイドルタイムの設定</TableCell>
              <TableCell align="right">一時受付停止</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slots.map((slot, index) => (
              <TableRow key={index}>
                <TableCell component="th" style={{ width: "20px" }} scope="row">
                  {slot.company_id}
                </TableCell>
                <TableCell align="right" style={{ width: "20px" }}>
                  {slot.shop_id}
                </TableCell>
                <TableCell align="right" style={{ width: "20px" }}>
                  {selectedShop?.name}
                </TableCell>
                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: slot.start,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(slot, "start", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>
                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: slot.end,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(slot, "end", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>
                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: slot.num,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(slot, "num", Number(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "number",
                  })}
                </TableCell>

                <TableCell align="right">
                  <div>
                    設定値:
                    {slot.idle_times
                      ? JSON.stringify(slot.idle_times)
                      : "設定なし"}
                  </div>
                  <div>
                    <TextField
                      required
                      id="idle-time-start-input"
                      label="アイドルタイム開始時間"
                      value={idleTimeStart}
                      variant="filled"
                      type="string"
                      onChange={ev => setIdleTimeStart(ev.target.value)}
                    />
                  </div>
                  <div>
                    <TextField
                      required
                      id="idle-time-end-input"
                      label="アイドルタイム終了時間"
                      value={idleTimeEnd}
                      variant="filled"
                      type="string"
                      onChange={ev => setIdleTimeEnd(ev.target.value)}
                    />
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        if (idleTimeStart && idleTimeEnd) {
                          update(slot, "idle_times", [
                            { start: idleTimeStart, end: idleTimeEnd },
                          ]);
                        }
                      }}
                    >
                      {" "}
                      設定する{" "}
                    </Button>
                    <Button
                      onClick={() => {
                        update(slot, "idle_times", []);
                      }}
                    >
                      {" "}
                      削除する{" "}
                    </Button>
                  </div>
                </TableCell>

                <TableCell align="right">
                  {JSON.stringify(slot.emergency_stop)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>

      {selectedShop && (
        <CreateSlotDialog
          shop={selectedShop}
          isOpen={createSlotModalOpen}
          onClose={() => setCreateSlotModalOpen(false)}
        />
      )}
    </>
  );
};

export default Slots;

export interface ShopClient {
  company_id: string;
  shop_id: string;

  // FIXME: not in use ?
  // shop_ids?: string[];

  shop_client_id: string;
  name: string;
  roles: ShopClientRole[];
  description?: string;
}

export enum ShopClientRole {
  ADMIN = "admin",
  SHOP_ADMIN = "shop_admin",
  SHOP = "shop",
  SHOP_STRIPE = "shop_stripe",
}

import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  Button,
  TextField,
} from "@material-ui/core";
import { Choice } from "../../../../../entity/choice.entity";
import { Shop } from "../../../../../entity/shop.entity";

export interface Props {
  shop: Shop;
  choices: Choice[];
  tableClassName: string;
  updateChoice: (
    choiceId: string,
    updateValue: Partial<Choice>,
  ) => Promise<void>;
}

export const ChoiceComponent: React.FC<Props> = ({
  shop,
  choices,
  tableClassName,
  updateChoice,
}: Props) => {
  const updateChoiceValues: { [key: string]: Partial<Choice> } = {};
  choices.forEach(choice => (updateChoiceValues[choice.choice_id] = {}));

  const applyChoiceChange = (choiceId: string) => {
    updateChoice(choiceId, updateChoiceValues[choiceId]).then(() => {
      updateChoiceValues[choiceId] = {};
    });
  };

  return (
    <div
      style={{
        margin: "10px",
        padding: "10px",
        background: "white",
        border: "solid 1px black",
      }}
    >
      <h2>チョイス</h2>
      <Table
        className={tableClassName}
        aria-label="simple table"
        style={{ border: "1px solid #4a4a4a" }}
      >
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "20px" }} align="left">
              Company ID
            </TableCell>
            <TableCell style={{ width: "20px" }} align="right">
              Shop ID
            </TableCell>
            <TableCell style={{ width: "20px" }} align="right">
              Shop Name
            </TableCell>
            <TableCell style={{ width: "20px" }} align="right">
              Choice ID
            </TableCell>
            <TableCell align="left">チョイス名</TableCell>
            <TableCell align="left">店舗表示名</TableCell>
            <TableCell align="left">値段</TableCell>
            <TableCell align="left">表示・非表示</TableCell>
            <TableCell align="left">売り切れ</TableCell>
            <TableCell align="left">画像URL</TableCell>
            <TableCell align="left">表示順(低いほど上)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {choices.map(choice => (
            <TableRow key={choice.choice_id}>
              <TableCell>{choice.company_id}</TableCell>
              <TableCell>{choice.shop_id}</TableCell>
              <TableCell>{shop.name}</TableCell>
              <TableCell>{choice.choice_id}</TableCell>
              <TableCell>
                <p>
                  チョイス名: {choice.name}
                  <TextField
                    defaultValue={choice.name}
                    type="string"
                    id="filled-basic"
                    label="name"
                    variant="filled"
                    onChange={ev => {
                      updateChoiceValues[choice.choice_id]["name"] =
                        ev.target.value;
                    }}
                  />
                </p>
                <p>
                  <Button onClick={() => applyChoiceChange(choice.choice_id)}>
                    Apply
                  </Button>
                </p>
              </TableCell>
              <TableCell>
                <p>
                  チョイスの店舗表示名: {choice.shop_display_name}
                  <TextField
                    defaultValue={choice.shop_display_name}
                    type="string"
                    id="filled-basic"
                    label="店舗側表記"
                    variant="filled"
                    onChange={ev => {
                      updateChoiceValues[choice.choice_id][
                        "shop_display_name"
                      ] = ev.target.value;
                    }}
                  />
                </p>
                <p>
                  <Button onClick={() => applyChoiceChange(choice.choice_id)}>
                    Apply
                  </Button>
                </p>
              </TableCell>
              <TableCell>
                <p>
                  値段: {choice.price}
                  <TextField
                    defaultValue={choice.price}
                    type="number"
                    id="filled-basic"
                    label="price"
                    variant="filled"
                    onChange={ev => {
                      updateChoiceValues[choice.choice_id]["price"] = Number(
                        ev.target.value,
                      );
                    }}
                  />
                </p>
                <p>
                  <Button onClick={() => applyChoiceChange(choice.choice_id)}>
                    Apply
                  </Button>
                </p>
              </TableCell>
              <TableCell>
                <Switch
                  required
                  checked={choice.is_display}
                  onChange={() =>
                    updateChoice(choice.choice_id, {
                      is_display: !choice.is_display,
                    })
                  }
                />
              </TableCell>
              <TableCell>
                <Switch
                  required
                  checked={choice.is_soldout}
                  onChange={() =>
                    updateChoice(choice.choice_id, {
                      is_soldout: !choice.is_soldout,
                    })
                  }
                />
              </TableCell>
              <TableCell>
                <p>
                  画像URL: {choice.image_url || ""}
                  <TextField
                    defaultValue={choice.image_url}
                    type="string"
                    id="filled-basic"
                    label="画像のURL"
                    variant="filled"
                    onChange={ev => {
                      updateChoiceValues[choice.choice_id][
                        "image_url"
                      ] = String(ev.target.value);
                    }}
                  />
                </p>
                <p>
                  <Button onClick={() => applyChoiceChange(choice.choice_id)}>
                    Apply
                  </Button>
                </p>
              </TableCell>
              <TableCell>
                <p>
                  表示順: {choice.priority}
                  <TextField
                    defaultValue={choice.priority}
                    type="number"
                    id="filled-basic"
                    label="priority"
                    variant="filled"
                    onChange={ev => {
                      updateChoiceValues[choice.choice_id]["priority"] = Number(
                        ev.target.value,
                      );
                    }}
                  />
                </p>
                <p>
                  <Button onClick={() => applyChoiceChange(choice.choice_id)}>
                    Apply
                  </Button>
                </p>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  createStyles,
  makeStyles,
  Theme,
  DialogContentText,
  DialogActions,
  Paper,
  Button,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { Shop } from "../../../../entity/shop.entity";
import { Menu } from "../../../../entity/menu.entity";
import { Option } from "../../../../entity/option.entity";
import { Choice } from "../../../../entity/choice.entity";
import { OptionComponent } from "./components/OptionComponent";
import { ChoiceComponent } from "./components/ChoiceComponent";
import {
  updateMenu,
  updateOption,
  updateChoice,
  getOptions,
} from "../../../../plugins/requests";
import { OptionIdEditorDialog } from "./components/OptionIdsEditor";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    form: {
      "& > *": {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
    list: {
      maxHeight: "250px",
      width: "280px",
      overflow: "auto",
    },
  }),
);

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface Props {
  menu: Menu | null;
  reloadMenu: (menuId: string) => void;
  shop: Shop;
  isOpen: boolean;
  onClose: () => void;
}

const OptionDialog: React.FC<Props> = ({
  shop,
  menu,
  reloadMenu,
  isOpen,
  onClose,
}: Props) => {
  const classes = useStyles();

  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);
  const [openOptionIdsEditor, setOpenOptionIdsEditor] = useState(false);
  const [openChoiceIdsEditor, setOpenChoiceIdsEditor] = useState(
    {} as { [optionId: string]: boolean },
  );
  const [options, setOptions] = useState([] as Option[]);

  useEffect(() => {
    getOptions(shop.company_id, shop.shop_id).then(options => {
      setOptions(options);
      options.forEach(option => {
        setOpenChoiceIdsEditor(prev => ({
          ...prev,
          [option.option_id]: false,
        }));
      });
    });
  }, [shop.company_id, shop.shop_id]);

  const applyChoiceChange = async (
    choiceId: string,
    updateValue: Partial<Choice>,
  ) => {
    if (!menu) return;
    await updateChoice(
      shop.company_id,
      shop.shop_id,
      choiceId,
      updateValue,
    ).catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
    reloadMenu(menu.menu_id);
  };

  const applyOptionChange = async (
    option: Option,
    updateValue: Partial<Option>,
  ) => {
    if (!menu) return;

    const toBeUpdatedOption: Option = { ...option, ...updateValue };

    // Note: isMultiple true だと，セレクトボタンになる
    //  isRequired true だと，ラジオボタンになる
    //  maxChoiceNum = 2 => isMultiple = true
    //  minChoiceNum = 0 => isMultiple = true, isRequired = false
    //  maxCHoiceNum = minChoiceNum => isRequired = true
    const isRequired = toBeUpdatedOption.min_choice_num > 0;
    const isMultiple =
      toBeUpdatedOption.max_choice_num > 1 ||
      toBeUpdatedOption.min_choice_num === 0;
    console.log(
      "also update flags:",
      isRequired,
      isMultiple,
      toBeUpdatedOption,
    );

    await updateOption(shop.company_id, shop.shop_id, option.option_id, {
      ...updateValue,
      is_required: isRequired,
      is_multiple: isMultiple,
    }).catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
    reloadMenu(menu.menu_id);
  };

  const updateOptionIds = async (optionId: string, updateValue: boolean) => {
    if (!menu) return;

    if (menu.option_ids?.includes(optionId) && !updateValue) {
      const updatedOptionIds = menu.option_ids.filter(id => id !== optionId);
      await updateMenu(menu.company_id, menu.shop_id, menu.menu_id, {
        option_ids: updatedOptionIds,
      });
    } else if (!menu.option_ids?.includes(optionId) && updateValue) {
      const updatedOptionIds = menu.option_ids
        ? [...menu.option_ids, optionId]
        : [optionId];
      await updateMenu(menu.company_id, menu.shop_id, menu.menu_id, {
        option_ids: updatedOptionIds,
      });
    }

    reloadMenu(menu.menu_id);
  };

  return (
    menu && (
      <>
        <Dialog
          fullScreen
          TransitionComponent={Transition}
          open={isOpen}
          onClose={onClose}
        >
          <DialogTitle id="scroll-dialog-title">Option</DialogTitle>
          <DialogContent>
            <div
              style={{
                margin: "30px",
                padding: "20px",
                border: "solid 1px #333333",
              }}
            >
              <p>
                Option Note:
                <br />
                maxChoiceNum: 最大チョイス選択可能数
                <br />
                minChoiceNum: 最低チョイス選択数
              </p>
              <p>
                Choice Note:
                <br />
                isDefaultSelection: 初期選択フラグチョイス
                <br />
              </p>
            </div>

            <Button
              variant="outlined"
              color="primary"
              style={{ width: "20vw", marginBottom: "30px" }}
              onClick={() => setOpenOptionIdsEditor(true)}
            >
              オプションの追加
            </Button>

            <OptionIdEditorDialog
              isOpen={openOptionIdsEditor}
              menu={menu}
              options={options}
              className={classes.list}
              updateValue={(optionId, updatedValue) => {
                updateOptionIds(optionId, updatedValue);
              }}
              onClose={() => setOpenOptionIdsEditor(false)}
            />

            {menu?.options?.map((option, optionIndex) => (
              <Paper
                key={optionIndex}
                style={{
                  background: "#c0c0c0",
                  padding: "5px",
                  marginTop: "12px",
                }}
              >
                <OptionComponent
                  choices={[] as Choice[]}
                  option={option}
                  updateOption={updateValue =>
                    applyOptionChange(option, updateValue)
                  }
                  openChoiceIdsEditor={openChoiceIdsEditor[option.option_id]}
                  setOpenChoiceIdsEditor={(val: boolean) => {
                    setOpenChoiceIdsEditor(prev => ({
                      ...prev,
                      [option.option_id]: val,
                    }));
                  }}
                />

                <ChoiceComponent
                  shop={shop}
                  choices={option.choices ?? []}
                  tableClassName={classes.table}
                  updateChoice={(choiceId, updateValue) =>
                    applyChoiceChange(choiceId, updateValue)
                  }
                />
              </Paper>
            ))}
            <Button onClick={onClose}>close</Button>
          </DialogContent>
        </Dialog>
        <Dialog
          open={dialogMessage !== null}
          onClose={() => setDialogMessage(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Result</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions />
        </Dialog>
      </>
    )
  );
};

export default OptionDialog;

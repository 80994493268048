import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Slide,
  TextField,
  createStyles,
  makeStyles,
  MenuItem,
  Theme,
  Button,
  Select,
  Switch,
  InputLabel,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { createShop, CreateShopRequest } from "../../../plugins/requests";
import { Company } from "../../../entity/company.entity";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: 800,
      },
    },
  }),
);
// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface Props {
  isOpen: boolean;
  onClose: () => void;
  companies: Company[];
}

const CreateShopDialog: React.FC<Props> = ({
  companies,
  isOpen,
  onClose,
}: Props) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [ownerId, setOwnerId] = useState("");
  const [companyId, setCompanyId] = useState("com_000");
  const [open, setOpen] = useState("07:00");
  const [close, setClose] = useState("23:00");
  const [readyTime, setReadyTime] = useState(10);
  const [priority, setPriority] = useState(0);
  const [mapImageUrl, setMapImageUrl] = useState(
    "https://s3-ap-northeast-1.amazonaws.com/assets.stag.mo.dinii.jp/app-images/sato_000/sh_000/shop.jpg",
  );
  const [topImageUrl, setTopImageUrl] = useState(
    "https://s3-ap-northeast-1.amazonaws.com/assets.stag.mo.dinii.jp/app-images/sato_000/sh_000/shop.jpg",
  );
  const [naviImageUrl, setNaviImageUrl] = useState(
    "https://s3-ap-northeast-1.amazonaws.com/assets.stag.mo.dinii.jp/app-images/sato_000/sh_000/shop.jpg",
  );
  const [exTax, setExTax] = useState(true);
  const [taxRate, setTaxRate] = useState(0.08);
  const [maxNum, setMaxNum] = useState(10);
  const [slackPostChannel, setSlackPostChannel] = useState(
    null as string | null,
  );

  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const create = () => {
    const createShopRequest: CreateShopRequest = {
      owner_id: ownerId,
      name,
      open,
      close,
      ready_time: readyTime,
      top_image_url: topImageUrl,
      navi_image_url: naviImageUrl,
      ex_tax: exTax,
      tax_rate: taxRate,
      priority,
      map_image_url: mapImageUrl,
      categories: [],
      menu_types: [],
      max_num: maxNum,
      slack_post_channel: slackPostChannel ? slackPostChannel : undefined,
    };
    createShop(companyId, createShopRequest)
      .then(() => {
        setDialogMessage("update success");
      })
      .catch(err => {
        setDialogMessage(JSON.stringify(err));
      });
  };

  return (
    <>
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle id="scroll-dialog-title">店舗作成</DialogTitle>
        <DialogContent>
          <form className={classes.root} autoComplete="off">
            <div>
              <TextField
                required
                id="name-input"
                label="Name"
                value={name}
                variant="filled"
                type="string"
                onChange={ev => setName(ev.target.value)}
              />

              <div style={{ margin: "15px" }}>
                <p>OwnerIdは基本的にcompany_idと同じ値を入力する</p>
                <TextField
                  required
                  id="owner-input"
                  label="OwnerId"
                  value={ownerId}
                  variant="filled"
                  type="string"
                  onChange={ev => setOwnerId(ev.target.value)}
                />
              </div>

              <FormControl>
                <InputLabel id="company-id-select-label">CompanyId</InputLabel>
                <Select
                  labelId="company-id-select-label"
                  id="company-id-select"
                  value={companyId}
                  type="string"
                  onChange={ev => setCompanyId(String(ev.target.value))}
                >
                  {companies.map(company => (
                    <MenuItem
                      key={company.company_id}
                      value={company.company_id}
                    >
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                required
                id="open-input"
                label="Open(HH:MM)"
                variant="filled"
                value={open}
                type="string"
                inputProps={{ pattern: "[0-9][0-9]:[0-9][0-9]" }}
                onChange={ev => setOpen(ev.target.value)}
              />

              <TextField
                required
                id="close-input"
                label="Close(HH:MM)"
                variant="filled"
                value={close}
                type="string"
                inputProps={{ pattern: "[0-9][0-9]:[0-9][0-9]" }}
                onChange={ev => setClose(ev.target.value)}
              />

              <TextField
                required
                id="ready-time-input"
                label="最短待ち受け時間"
                variant="filled"
                value={readyTime}
                type="number"
                onChange={ev => setReadyTime(Number(ev.target.value))}
              />

              <TextField
                required
                id="max-num-input"
                label="一注文あたりの上限個数"
                variant="filled"
                value={maxNum}
                type="number"
                onChange={ev => setMaxNum(Number(ev.target.value))}
              />
            </div>

            <div>
              <TextField
                id="map-image-url-input"
                value={mapImageUrl}
                label="Map Image URL"
                variant="filled"
                type="string"
                onChange={ev => setMapImageUrl(ev.target.value)}
              />
              <TextField
                id="top-image-url-input"
                value={topImageUrl}
                label="Top Image URL"
                variant="filled"
                type="string"
                onChange={ev => setTopImageUrl(ev.target.value)}
              />
              <TextField
                id="navi-image-url-input"
                label="Navi Image URL"
                value={naviImageUrl}
                variant="filled"
                type="string"
                onChange={ev => setNaviImageUrl(ev.target.value)}
              />
              <TextField
                required
                id="priority-input"
                value={priority}
                label="priority"
                variant="filled"
                type="number"
                onChange={ev => setPriority(Number(ev.target.value))}
              />
            </div>

            <div>
              <InputLabel>ex tax</InputLabel>
              <Switch
                required
                checked={exTax}
                onChange={() => setExTax(!exTax)}
                value="checkedA"
              />
              <TextField
                required
                id="tax-rate-input"
                value={taxRate}
                label="taxRate"
                variant="filled"
                type="number"
                onChange={ev => setTaxRate(parseInt(ev.target.value, 10))}
              />
            </div>

            <div>
              <InputLabel>Slack送信チャンネル名(あとで設定可能)</InputLabel>
              <TextField
                required
                id="slack-channel-input"
                value={slackPostChannel}
                label="slackChannel"
                variant="filled"
                type="string"
                onChange={ev => setSlackPostChannel(ev.target.value)}
              />
            </div>

            <div>
              <Button type="button" onClick={create}>
                Apply
              </Button>
              <Button onClick={onClose}> close </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default CreateShopDialog;

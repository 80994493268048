import React from "react";
import DeviceStatus from "./devicestatus/DeviceStatus";
import Shops from "./dataManagement/shops/Shops";
import Menus from "./dataManagement/menus/Menus";
import Categories from "./dataManagement/categories/Categories";
import MenuTypes from "./dataManagement/menuTypes/MenuTypes";
import Options from "./dataManagement/options/Options";
import Choices from "./dataManagement/choices/Choices";
import Companies from "./dataManagement/companies/Companies";
import Slots from "./dataManagement/slots/Slots";
import ShopClients from "./dataManagement/shopClients/ShopClients";
import Orders from "./dataManagement/orders/Orders";
import CopyMenus from "./dataManagement/copy_menus/CopyMenus";
import PaymentManagement from "./dataManagement/paymentManagement/PaymentManagement";

export interface Route {
  title: string;
  path: string;
  component: React.FC;
}

export const DeviceStatusRoute: Route = {
  title: "端末監視",
  path: "/",
  component: DeviceStatus,
};

export const ConnectionRoute: Route = {
  title: "接続監視",
  path: "/connection_status",
  component: DeviceStatus,
};

export const CompaniesRoute: Route = {
  title: "Company設定",
  path: "/companies",
  component: Companies,
};

export const ShopsRoute: Route = {
  title: "店舗設定",
  path: "/shops",
  component: Shops,
};

export const SlotsRoute: Route = {
  title: "スロット設定",
  path: "/slots",
  component: Slots,
};

export const ShopClientsRoute: Route = {
  title: "店舗アカウント設定",
  path: "/shop_clients",
  component: ShopClients,
};

export const MenusRoute: Route = {
  title: "メニュー設定",
  path: "/menus",
  component: Menus,
};

export const CategoriesRoute: Route = {
  title: "カテゴリ設定",
  path: "/categories",
  component: Categories,
};

export const MenuTypesRoute: Route = {
  title: "メニュータイプ設定",
  path: "/menu_types",
  component: MenuTypes,
};

export const OptionsRoute: Route = {
  title: "オプション設定",
  path: "/options",
  component: Options,
};

export const ChoicesRoute: Route = {
  title: "チョイス設定",
  path: "/choices",
  component: Choices,
};

export const OrdersRoute: Route = {
  title: "オーダー一覧",
  path: "/orders",
  component: Orders,
};

export const CopyMenusRoute: Route = {
  title: "メニューのコピー",
  path: "/copy_menus",
  component: CopyMenus,
};

export const PaymentManagementRoute: Route = {
  title: "明細出力",
  path: "/payment_management",
  component: PaymentManagement,
};

export const router = [
  DeviceStatusRoute,
  ConnectionRoute,
  CompaniesRoute,
  ShopsRoute,
  SlotsRoute,
  ShopClientsRoute,
  CategoriesRoute,
  MenuTypesRoute,
  MenusRoute,
  OptionsRoute,
  ChoicesRoute,
  OrdersRoute,
  CopyMenusRoute,
  PaymentManagementRoute,
];

import { useCallback, useContext, useEffect, useState } from "react";
import { MainContext } from "../context/MainContext";
import { Shop } from "../entity/shop.entity";
import { Company } from "../entity/company.entity";
import { getCompanies, getShops } from "../plugins/requests";

export const useShops = (canReload?: boolean) => {
  const [mainContext, setMainContext] = useContext(MainContext);
  const { shops, companies, selectedShop } = mainContext;

  // TODO: use reducer
  const setCompanies = useCallback(
    (companies: Company[]) => {
      setMainContext({
        ...mainContext,
        companies: [...companies],
      });
    },
    [mainContext, setMainContext],
  );

  const setShops = useCallback(
    (shops: Shop[]) => {
      setMainContext({
        ...mainContext,
        shops: [...shops],
      });
    },
    [mainContext, setMainContext],
  );

  const setSelectedShop = useCallback(
    (shop: Shop | null) => {
      setMainContext({
        ...mainContext,
        selectedShop: shop,
      });
    },
    [mainContext, setMainContext],
  );

  const [isLoading, setIsLoading] = useState(false);

  const setIsInitialized = useCallback(
    (isInitialized: boolean) => {
      setMainContext({
        ...mainContext,
        isInitialized,
      });
    },
    [mainContext, setMainContext],
  );

  // load context
  useEffect(() => {
    if (canReload) {
      (async () => {
        if (mainContext.isInitialized) return;
        setIsLoading(true);
        try {
          const companies = await getCompanies();
          setMainContext(
            Object.assign(mainContext, {
              shops: [],
              companies,
              isInitialized: true,
            }),
          );

          const shopsDoubleList = await Promise.all(
            companies.map(company => getShops(company.company_id)),
          );
          const shopsList = shopsDoubleList.flatMap(shopList => shopList);
          setMainContext(
            Object.assign(mainContext, {
              shops: shopsList,
              companies,
              isInitialized: true,
            }),
          );
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [
    canReload,
    mainContext,
    mainContext.isInitialized,
    setMainContext,
    shops,
  ]);

  return {
    shops,
    companies,
    selectedShop,
    setSelectedShop,
    setShops,
    setCompanies,
    isLoading,
    setIsInitialized,
  };
};

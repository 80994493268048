import React from "react";
import { Button, TextField } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

interface Props {
  defaultValue: any;
  formClassName: string;
  applyChange: (editedValue: any) => void;
  cancelEdit: () => void;
  startEdit: () => void;
  isShowEditForm: boolean;
  dataType?: string;
}

const DataTableRowComponent: React.FC<Props> = ({
  defaultValue,
  formClassName,
  applyChange,
  cancelEdit,
  startEdit,
  isShowEditForm,
  dataType,
}: Props) => {
  let editedValue = "";
  const setEditedValue = (val: any) => {
    editedValue = val;
  };
  const apply = () => applyChange(editedValue);

  return (
    <>
      <p>{defaultValue}</p>
      {isShowEditForm ? (
        <>
          <form className={formClassName} noValidate autoComplete="off">
            <TextField
              defaultValue={defaultValue}
              type={dataType || "string"}
              id="filled-basic"
              label="Filled"
              variant="filled"
              onChange={ev => setEditedValue(ev.target.value)}
            />
          </form>
          <Button onClick={() => apply()}>Apply</Button>
          <Button onClick={cancelEdit}>Cancel</Button>
        </>
      ) : (
        <>
          <Button color="primary" onClick={() => startEdit()}>
            <EditIcon style={{ width: "15px" }} />
          </Button>
        </>
      )}
    </>
  );
};

export default DataTableRowComponent;

import React, { useState, useCallback } from "react";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // eslint-disable-next-line no-unused-vars
  Theme,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import DataTableRowComponent from "../../components/dataTableRowComponent";
import CreateMenuTypeDialog from "./CreateMenuTypeDialog";
import * as request from "../../../plugins/requests";
import { MenuType } from "../../../entity/menu_type.entity";
import { ShopSelection } from "../../components/shopSelection";
import { useShops } from "../../../hooks/useShops";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    form: {
      "& > *": {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
    list: {
      maxHeight: "250px",
      width: "280px",
      overflow: "auto",
    },
  }),
);

const MenuTypes: React.FC = () => {
  const classes = useStyles();

  const {
    companies,
    shops,
    selectedShop,
    setShops,
    setSelectedShop,
  } = useShops();
  const menuTypes = useCallback(() => selectedShop?.menu_types || [], [
    selectedShop,
  ]);

  // edit flags
  const [editFocusIndex, setEditFocusIndex] = useState(null as null | number);

  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const [createMenuTypeModalOpen, setCreateMenuTypeModalOpen] = useState(false);

  const updateMenuType = <K extends keyof MenuType>(
    menuType: MenuType,
    key: K,
    value: MenuType[K],
  ) => {
    if (!selectedShop) return;
    request
      .updateMenuType(selectedShop, menuType.menu_type_id, { [key]: value })
      .then(() => {
        setDialogMessage("update success");
        reloadShops();
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
    setEditFocusIndex(null);
  };

  const reloadShops = () => {
    setShops([]);
    companies.forEach(company => {
      request.getShops(company.company_id).then(shops_ => {
        setShops([...shops, ...shops_]);
        const targetShop = shops.find(
          shop => shop.shop_id === selectedShop?.shop_id,
        );
        if (targetShop) {
          setSelectedShop(targetShop);
        }
      });
    });
  };

  const selectShop = (shopId: string) => {
    const shop = shops.find(_shop => _shop.shop_id === shopId);
    if (!shop) return;
    const company = companies.find(
      _company => _company.company_id === shop.company_id,
    );
    if (!company) return;
    setSelectedShop(shop);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        style={{ width: "70vw", marginBottom: "30px" }}
        onClick={() => setCreateMenuTypeModalOpen(true)}
      >
        メニュータイプを作成する
      </Button>

      <ShopSelection
        selectedShop={selectedShop}
        shops={shops}
        selectShop={selectShop}
      />

      <Paper>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">MenuType Id</TableCell>
              <TableCell align="right">メニュータイプ名</TableCell>
              <TableCell align="right">表示順(低いほど上に表示)</TableCell>
              <TableCell align="right">種別</TableCell>
              <TableCell align="right">提供開始時間</TableCell>
              <TableCell align="right">提供終了時間</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {menuTypes().map((menuType, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {selectedShop?.company_id || ""}
                </TableCell>
                <TableCell align="right">
                  {selectedShop?.shop_id || ""}
                </TableCell>
                <TableCell align="right">{selectedShop?.name || ""}</TableCell>
                <TableCell align="right">{menuType.menu_type_id}</TableCell>
                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: menuType.name,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      updateMenuType(menuType, "name", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>
                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: menuType.priority,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      updateMenuType(menuType, "priority", Number(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "number",
                  })}
                </TableCell>
                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: menuType.type,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      updateMenuType(menuType, "type", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: menuType.condition.start,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      updateMenuType(menuType, "condition", {
                        start: val,
                        end: menuType.condition.end,
                      });
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: menuType.condition.end,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      updateMenuType(menuType, "condition", {
                        start: menuType.condition.start,
                        end: val,
                      });
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>

      {selectedShop && (
        <CreateMenuTypeDialog
          shop={selectedShop}
          isOpen={createMenuTypeModalOpen}
          onClose={() => setCreateMenuTypeModalOpen(false)}
          reloadShops={() => reloadShops()}
        />
      )}
    </>
  );
};

export default MenuTypes;

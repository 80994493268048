import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

let firebaseConfig = {
  apiKey: "AIzaSyBbFfUrkrjXfgZe08yCj-0nlvddbw0SBbc",
  authDomain: "dinii-for-stag.firebaseapp.com",
  databaseURL: "https://dinii-for-stag.firebaseio.com",
  projectId: "dinii-for-stag",
  storageBucket: "dinii-for-stag.appspot.com",
  messagingSenderId: "195585275834",
  appId: "1:195585275834:web:9e4d29a925ebe12d704f83",
};

switch (process.env.REACT_APP_ENV) {
  case "staging":
    firebaseConfig = {
      apiKey: "AIzaSyBbFfUrkrjXfgZe08yCj-0nlvddbw0SBbc",
      authDomain: "dinii-for-stag.firebaseapp.com",
      databaseURL: "https://dinii-for-stag.firebaseio.com",
      projectId: "dinii-for-stag",
      storageBucket: "dinii-for-stag.appspot.com",
      messagingSenderId: "195585275834",
      appId: "1:195585275834:web:9e4d29a925ebe12d704f83",
    };
    break;
  case "production":
    firebaseConfig = {
      apiKey: "AIzaSyBKnERM-tjLSrOTNIY_KsK33qT21dygpYk",
      authDomain: "dinii-for-shop.firebaseapp.com",
      databaseURL: "https://dinii-for-shop.firebaseio.com",
      projectId: "dinii-for-shop",
      storageBucket: "dinii-for-shop.appspot.com",
      messagingSenderId: "1014396531993",
      appId: "1:1014396531993:web:3bc7c87b9524e84c5fc188",
    };
    break;
}

firebase.initializeApp(firebaseConfig);

export default firebase;

import React, { useState } from "react";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // eslint-disable-next-line no-unused-vars
  Theme,
  Link,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import CreateCompanyDialog from "./CreateCompanyDialog";
import DataTableRowComponent from "../../components/dataTableRowComponent";
import { Company } from "../../../entity/company.entity";
import { updateCompany } from "../../../plugins/requests";
import { useShops } from "../../../hooks/useShops";

const makeURL = (domainPrefix: string): string =>
  `https://${domainPrefix}.for.dinii.jp/`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    form: {
      "& > *": {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

const Companies: React.FC = () => {
  const classes = useStyles();

  const { companies, setCompanies } = useShops();

  // edit flags
  const [editFocusIndex, setEditFocusIndex] = useState(null as null | number);

  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const [createCompanyModalOpen, setCreateCompanyModalOpen] = useState(false);

  const update = async <K extends keyof Company>(
    company: Company,
    key: K,
    value: Company[K],
  ) => {
    try {
      const updatedCompany = await updateCompany(company.company_id, {
        [key]: value,
      });
      setCompanies(
        companies.map(existingCompany =>
          existingCompany.company_id === updatedCompany.company_id
            ? updatedCompany
            : existingCompany,
        ),
      );
      setDialogMessage("update success");
    } catch (err) {
      setDialogMessage(JSON.stringify(err, null, 2));
    } finally {
      setEditFocusIndex(null);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        style={{ width: "70vw", marginBottom: "30px" }}
        onClick={() => setCreateCompanyModalOpen(true)}
      >
        Companyを作成する
      </Button>

      <Paper>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="right">企業名</TableCell>
              <TableCell align="right">アプリ表題</TableCell>
              <TableCell align="right">アプリリンク</TableCell>
              <TableCell align="right">owner id(owner_group_id)</TableCell>
              <TableCell align="right">liff id</TableCell>
              <TableCell align="right">domain prefix</TableCell>
              <TableCell align="right">stripe platform id</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company, index) => (
              <TableRow key={index}>
                <TableCell component="th" style={{ width: "20px" }} scope="row">
                  {company.company_id}
                </TableCell>
                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: company.name,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(company, "name", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>
                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: company.company_name,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(company, "company_name", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>

                <TableCell align="right">
                  <Link
                    href="#"
                    onClick={() => window.open(makeURL(company.domain_prefix))}
                  >
                    {makeURL(company.domain_prefix)}
                  </Link>
                </TableCell>

                <TableCell align="right" style={{ width: "20px" }}>
                  {DataTableRowComponent({
                    defaultValue: company.owner_id,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(company, "owner_id", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>
                <TableCell align="right" style={{ width: "20px" }}>
                  {DataTableRowComponent({
                    defaultValue: company.liff_id,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(company, "liff_id", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>
                <TableCell align="right" style={{ width: "20px" }}>
                  {DataTableRowComponent({
                    defaultValue: company.domain_prefix,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(company, "domain_prefix", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>
                <TableCell align="right" style={{ width: "20px" }}>
                  {company.platform_id}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>

      <CreateCompanyDialog
        isOpen={createCompanyModalOpen}
        onClose={() => setCreateCompanyModalOpen(false)}
      />
    </>
  );
};

export default Companies;

import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core";
import firebase from "../../plugins/firebase";
import { green, red } from "@material-ui/core/colors";
import clsx from "clsx";
import DeviceLogsDialog from "./DeviceLogsDialog/DeviceLogsDialog";
import * as request from "../../plugins/requests";
import { Shop } from "../../entity/shop.entity";
import { Company } from "../../entity/company.entity";
const Moment = require("moment");

enum DEVICE_COMMAND {
  START = "startListenPrinter",
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  okCell: {
    backgroundColor: green.A100,
  },
  ngCell: {
    backgroundColor: red.A100,
  },
});

enum LogType {
  startOperation = "startOperation",
  waitingHome = "waitingHome",
  reboot = "reboot",
  receiveRebootCommand = "receiveRebootCommand",
  receiveSoftRebootCommand = "receiveSoftRebootCommand",
}

interface DeviceStatus {
  lastRebootedAt?: any;
  lastUpdatedAt: any;
  lastUpdatedType: LogType;
  companyId: string;
  shopId: string;
  shopClientId: string;
  battery: number;
  appVersion: string;
}

const DeviceStatus: React.FC = () => {
  const classes = useStyles();
  const [statuses, setStatuses] = useState([] as DeviceStatus[]);
  const [selectedStatus, setSelectedStatus] = useState({} as DeviceStatus);
  const [logModalOpen, setLogModalOpen] = useState(false);
  const [shops, setShops] = useState([] as Shop[]);
  const [companies, setCompanies] = useState([] as Company[]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("device-status")
      .orderBy("lastUpdatedAt", "desc")
      .onSnapshot(
        snapshot => {
          console.log("get query");
          const logs = snapshot.docs.map(value => {
            return value.data();
          }) as DeviceStatus[];
          setStatuses(logs);
        },
        err => {
          console.log(`Encountered error: ${err}`);
        },
      );
    return unsubscribe;
  }, []);

  useEffect(() => {
    request.getCompanies().then(companies => {
      setCompanies([...companies]);
    });
  }, []);

  useEffect(() => {
    companies.forEach(company => {
      request.getShops(company.company_id).then(shops => {
        setShops(old => [...old, ...shops]);
      });
    });
  }, [companies]);

  const sendCommand = async (shopClientId: string, command: DEVICE_COMMAND) => {
    if (window.confirm(`コマンド ${command} を ${shopClientId} に実行します`)) {
      await firebase
        .firestore()
        .collection("deviceRemoteManagement")
        .doc(shopClientId)
        .set({ command: "none" }, { merge: true });
      await firebase
        .firestore()
        .collection("deviceRemoteManagement")
        .doc(shopClientId)
        .set({ command }, { merge: true });
    } else {
      window.alert("キャンセルしました");
    }
  };

  const softReboot = async (shopClientId: string) => {
    if (window.confirm("アプリ再起動しますか？")) {
      await firebase
        .firestore()
        .collection("deviceRemoteManagement")
        .doc(shopClientId)
        .set(
          { softRebootId: firebase.firestore.FieldValue.increment(1) },
          { merge: true },
        );
    } else {
      window.alert("キャンセルしました");
    }
  };

  const hardReboot = async (shopClientId: string) => {
    if (window.confirm("端末再起動しますか？")) {
      await firebase
        .firestore()
        .collection("deviceRemoteManagement")
        .doc(shopClientId)
        .set(
          { rebootId: firebase.firestore.FieldValue.increment(1) },
          { merge: true },
        );
    } else {
      window.alert("キャンセルしました");
    }
  };

  const getShopName = (shopId: string): string => {
    const ret = shops.find(shop => shop.shop_id === shopId);
    return ret?.name || "";
  };

  const getCompanyName = (companyId: string): string => {
    const ret = companies.find(company => company.company_id === companyId);
    return ret?.name || "";
  };

  return (
    <>
      <Paper>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Company ID</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">Shop Client ID</TableCell>
              <TableCell align="right">Last updated at</TableCell>
              <TableCell align="right">Last updated type</TableCell>
              <TableCell align="right">Battery</TableCell>
              <TableCell align="right">Last rebooted at</TableCell>
              <TableCell align="right">App version</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statuses.map((status, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {status.companyId}
                </TableCell>
                <TableCell component="th" scope="row">
                  {getCompanyName(status.companyId)}
                </TableCell>
                <TableCell align="right">{status.shopId}</TableCell>
                <TableCell align="right">
                  {getShopName(status.shopId)}
                </TableCell>
                <TableCell align="right">{status.shopClientId}</TableCell>
                <TableCell align="right">
                  {Moment(status.lastUpdatedAt.toDate()).format(
                    "YYYY-MM-DD HH:mm:ss",
                  )}
                </TableCell>
                <TableCell align="right">{status.lastUpdatedType}</TableCell>
                <TableCell align="right">{status.battery}</TableCell>
                <TableCell
                  className={clsx(
                    status.lastRebootedAt &&
                      isToday(status.lastRebootedAt.toDate())
                      ? classes.okCell
                      : classes.ngCell,
                  )}
                  align="right"
                >
                  {status.lastRebootedAt &&
                    formatDate(status.lastRebootedAt.toDate())}
                </TableCell>
                <TableCell align="right">ver: {status.appVersion}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      setSelectedStatus(status);
                      setLogModalOpen(true);
                    }}
                  >
                    ログ表示
                  </Button>
                  <Button
                    onClick={() =>
                      sendCommand(status.shopClientId, DEVICE_COMMAND.START)
                    }
                  >
                    通信開始
                  </Button>
                  <Button onClick={() => softReboot(status.shopClientId)}>
                    アプリ再起動
                  </Button>
                  <Button onClick={() => hardReboot(status.shopClientId)}>
                    端末再起動
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <DeviceLogsDialog
        isOpen={logModalOpen}
        companyId={selectedStatus.companyId}
        shopId={selectedStatus.shopId}
        shopClientId={selectedStatus.shopClientId}
        onClose={() => setLogModalOpen(false)}
      />
    </>
  );
};

const formatDate = (date: Date) => {
  return Moment(date).format("YYYY-MM-DD HH:mm:ss");
};

const isToday = (date: Date) => {
  const now = Moment().startOf("date");
  return Moment(date).diff(now) > 0;
};

export default DeviceStatus;

import React, { useEffect, useState } from "react";
import Main from "./Main";
import firebase from "../plugins/firebase";
import Login from "./Login";

const adminEmails = ["monitoring-admin@dinii.jp", "admin-viewer@dinii.jp"];

const Auth: React.FC = () => {
  const [isLogin, setIsLogin] = useState(false);
  useEffect(() => {
    if (!isLogin) {
      const current = firebase.auth().currentUser;
      if (current?.email && adminEmails.includes(current.email)) {
        setIsLogin(true);
      } else if (current) {
        firebase
          .auth()
          .signOut()
          .then();
      }
    }
    firebase.auth().onAuthStateChanged(user => {
      // FIXME: diniiに所属しないユーザが利用する場合、以下を制御する
      if (user?.email && adminEmails.includes(user.email)) {
        setIsLogin(true);
      } else {
        setIsLogin(false);
        if (user) {
          firebase
            .auth()
            .signOut()
            .then();
        }
      }
    });
    return () => {};
  }, [isLogin]);
  return isLogin ? <Main /> : <Login />;
};

export default Auth;

import request from "./api";
import { Shop } from "../entity/shop.entity";
import { Menu } from "../entity/menu.entity";
import { Category } from "../entity/category.entity";
import {
  MenuType,
  MenuConditionType,
  MenuCondition,
} from "../entity/menu_type.entity";
import { Company } from "../entity/company.entity";
import uuidv4 from "uuid/v4";
import { Option } from "../entity/option.entity";
import { Choice } from "../entity/choice.entity";
import { Slot } from "../entity/slot.entity";
import { ShopClientRole, ShopClient } from "../entity/shopClient.entity";
import axiosBase from "axios";
import { Order, OrderStatus } from "../entity/order.entity";
import moment from "moment-timezone";

export interface CreateCompanyRequest {
  company_id: string;
  name: string;
  company_name: string;
  owner_id: string;
  domain_prefix: string;
  navi_image_url: string;
  platform_id: string;
  liff_id?: string;
}

export const createCompany = async (
  companyId: string,
  createCompanyValue: CreateCompanyRequest,
): Promise<Company> => {
  const response = await request.post("admin/company", createCompanyValue, {
    headers: { "x-company-id": companyId },
  });
  return response.data;
};

export const getCompanies = async (): Promise<Company[]> => {
  const response = await request.get(`admin/company`);
  return response.data;
};

export const updateCompany = async (
  companyId: string,
  updateValue: Partial<Company>,
): Promise<Company> => {
  const response = await request.patch(
    `admin/company/${companyId}`,
    updateValue,
    {
      headers: { "x-company-id": companyId },
    },
  );
  return response.data;
};

export interface CreateShopRequest {
  name: string;
  open: string;
  close: string;
  top_image_url: string;
  navi_image_url: string;
  map_image_url: string;
  ex_tax: boolean;
  tax_rate: number;
  priority: number;
  owner_id: string;
  ready_time: number;
  max_num: number;
  categories: Category[];
  menu_types: MenuType[];
  slack_post_channel?: string;
}

export const createShop = async (
  companyId: string,
  createShopValue: CreateShopRequest,
): Promise<Shop> => {
  const response = await request.post("shops/_shop", createShopValue, {
    headers: { "x-company-id": companyId },
  });
  return response.data;
};

export const getShops = async (companyId: string): Promise<Shop[]> => {
  const response = await request.get("shops", {
    headers: { "x-company-id": companyId },
  });
  return response.data;
};

export const updateShop = async (
  companyId: string,
  shopId: string,
  updateValue: Partial<Shop>,
): Promise<Shop> => {
  const response = await request.patch(`shops/${shopId}`, updateValue, {
    headers: { "x-company-id": companyId },
  });
  return response.data;
};

export enum ShopImageKeys {
  MAP = "map_image_url",
  TOP = "top_image_url",
  navi = "navi_image_url",
}

export const createShopImageUrl = async (
  companyId: string,
  shopId: string,
  imageKey: ShopImageKeys,
): Promise<string> => {
  const response = await request.post(
    `shops/${shopId}/upload_image/${imageKey}`,
    { file_type: "image/jpeg" },
    { headers: { "x-company-id": companyId } },
  );
  return response.data;
};

export const uploadShopImage = async (
  file: File,
  fileName: string,
  signedUrl: string,
) => {
  const blob = file.slice(0, file.size, "image/jpeg");
  const newFile = new File([blob], fileName, { type: "image/jpeg" });
  await axiosBase.put(signedUrl, newFile, {
    headers: { "Content-Type": file.type },
  });
};

export const sendSummary = async (companyId: string, shopId: string) => {
  await request.get(`/admin/summary/${shopId}?send_to_slack=true`, {
    headers: { "x-company-id": companyId },
  });
};

export interface CreateShopClientRequest {
  email: string;
  password: string;
  name: string;
  roles: ShopClientRole[];
}

export const createShopClient = async (
  companyId: string,
  shopId: string,
  createShopClientValue: CreateShopClientRequest,
): Promise<ShopClient> => {
  const response = await request.post(
    `admin/shops/${shopId}/shop_clients`,
    {
      ...createShopClientValue,
      description: createShopClientValue.email, // NOTE: descriptionにemailを入れておく
    },
    {
      headers: { "x-company-id": companyId },
    },
  );
  return response.data;
};

export const getShopClients = async (
  companyId: string,
  shopId: string,
): Promise<ShopClient[]> => {
  const response = await request.get(`shops/${shopId}/shop_clients`, {
    headers: { "x-company-id": companyId },
  });
  return response.data;
};

export const updateShopClient = async (
  companyId: string,
  shopId: string,
  shopClientId: string,
  updateValue: Partial<ShopClient>,
): Promise<ShopClient> => {
  const response = await request.patch(
    `shops/${shopId}/shop_clients/${shopClientId}`,
    updateValue,
    {
      headers: { "x-company-id": companyId },
    },
  );
  return response.data;
};

export const createSlot = async (
  companyId: string,
  shopId: string,
  createSlotValue: Partial<Slot>,
): Promise<Slot> => {
  const response = await request.post(
    `admin/shops/${shopId}/slots`,
    createSlotValue,
    {
      headers: { "x-company-id": companyId },
    },
  );
  return response.data;
};

export const getSlot = async (
  companyId: string,
  shopId: string,
): Promise<Slot> => {
  const response = await request.get(`shops/${shopId}/slots`, {
    headers: { "x-company-id": companyId },
  });
  return response.data;
};

export const updateSlot = async (
  companyId: string,
  shopId: string,
  updateValue: Partial<Slot>,
): Promise<Slot> => {
  const response = await request.patch(`shops/${shopId}/slots`, updateValue, {
    headers: { "x-company-id": companyId },
  });
  return response.data;
};

export const getMenus = async (
  companyId: string,
  shopId: string,
  expandOption = true,
): Promise<Menu[]> => {
  const response = await request.get(
    `shops/${shopId}/menus?expand_option=${expandOption}`,
    {
      headers: { "x-company-id": companyId },
    },
  );
  return response.data;
};

export interface CreateMenuRequest {
  name: string;
  price: number;
  priority: number;
  category_id: string;
  menu_type_id: string;
  description?: string;
  image_url?: string | null;
  default_choices?: string;
  option_ids?: string[];
  max_num?: number;
  shop_display_name?: string;
}

export const getMenu = async (
  companyId: string,
  shopId: string,
  menuId: string,
): Promise<Menu> => {
  const response = await request.get(
    `shops/${shopId}/menus/${menuId}?expand_option=true`,
    {
      headers: { "x-company-id": companyId },
    },
  );
  return response.data;
};

export const createMenu = async (
  shop: Shop,
  createMenuRequest: CreateMenuRequest,
): Promise<Menu> => {
  const response = await request.post(
    `shops/${shop.shop_id}/menus`,
    createMenuRequest,
    {
      headers: { "x-company-id": shop.company_id },
    },
  );
  return response.data;
};

export const updateMenu = async (
  companyId: string,
  shopId: string,
  menuId: string,
  updateValue: Partial<Menu>,
): Promise<Menu> => {
  const response = await request.patch(
    `shops/${shopId}/menus/${menuId}`,
    updateValue,
    { headers: { "x-company-id": companyId } },
  );
  return response.data;
};

export const removeMenuProperty = async (
  companyId: string,
  shopId: string,
  menuId: string,
  removeKey: "shop_display_name" | "description" | "image_url",
): Promise<Menu> => {
  const response = await request.delete(
    `shops/${shopId}/menus/${menuId}/${removeKey}`,
    { headers: { "x-company-id": companyId } },
  );
  return response.data;
};

export const createMenuImageUrl = async (
  companyId: string,
  shopId: string,
  menuId: string,
): Promise<string> => {
  const response = await request.post(
    `shops/${shopId}/menus/${menuId}`,
    { file_type: "image/jpeg" },
    { headers: { "x-company-id": companyId } },
  );
  return response.data;
};

export const uploadMenuImage = async (
  file: File,
  fileName: string,
  signedUrl: string,
) => {
  const blob = file.slice(0, file.size, "image/jpeg");
  const newFile = new File([blob], fileName, { type: "image/jpeg" });
  await axiosBase.put(signedUrl, newFile, {
    headers: { "Content-Type": file.type },
  });
};

export const deleteMenu = async (
  companyId: string,
  shopId: string,
  menuId: string,
): Promise<void> => {
  await request.delete(`shops/${shopId}/menus/${menuId}`, {
    headers: { "x-company-id": companyId },
  });
};

export const getOptions = async (
  companyId: string,
  shopId: string,
): Promise<Option[]> => {
  const response = await request.get(`shops/${shopId}/options`, {
    headers: { "x-company-id": companyId },
  });

  return response.data;
};

export interface CreateOptionRequest {
  name: string;
  priority: number;
  max_choice_num: number;
  min_choice_num: number;
  is_required: boolean;
  is_multiple: boolean;
  choice_ids: string[];
}

export const createOption = async (
  companyId: string,
  shopId: string,
  createOptionRequest: CreateOptionRequest,
): Promise<Option> => {
  const response = await request.post(
    `shops/${shopId}/options`,
    createOptionRequest,
    {
      headers: { "x-company-id": companyId },
    },
  );
  return response.data;
};

export const updateOption = async (
  companyId: string,
  shopId: string,
  optionId: string,
  updateValue: Partial<Option>,
): Promise<Option> => {
  const response = await request.patch(
    `shops/${shopId}/options/${optionId}`,
    updateValue,
    { headers: { "x-company-id": companyId } },
  );
  return response.data;
};

export const deleteOption = async (
  companyId: string,
  shopId: string,
  optionId: string,
): Promise<void> => {
  await request.delete(`shops/${shopId}/options/${optionId}`, {
    headers: { "x-company-id": companyId },
  });
};

export interface CreateChoiceRequest {
  name: string;
  priority: number;
  price: number;
  shop_display_name: string;
}

export const createChoice = async (
  companyId: string,
  shopId: string,
  createChoiceRequest: CreateChoiceRequest,
): Promise<Choice> => {
  const response = await request.post(
    `shops/${shopId}/choices`,
    createChoiceRequest,
    {
      headers: { "x-company-id": companyId },
    },
  );
  return response.data;
};

export const getChoices = async (
  companyId: string,
  shopId: string,
): Promise<Choice[]> => {
  const response = await request.get(`shops/${shopId}/choices`, {
    headers: { "x-company-id": companyId },
  });

  return response.data;
};

export const updateChoice = async (
  companyId: string,
  shopId: string,
  choiceId: string,
  updateValue: Partial<Choice>,
): Promise<Choice> => {
  const response = await request.patch(
    `shops/${shopId}/choices/${choiceId}`,
    updateValue,
    {
      headers: { "x-company-id": companyId },
    },
  );
  return response.data;
};

export const deleteChoice = async (
  companyId: string,
  shopId: string,
  choiceId: string,
): Promise<void> => {
  await request.delete(`shops/${shopId}/choices/${choiceId}`, {
    headers: { "x-company-id": companyId },
  });
  return;
};

// TODO: get from shop?
// export const getCategories = async (companyId: string, shopId: string): Promise<Category[]> => {
//   const response = await request
//     .get(`shops/${shopId}/menus`, {
//       headers: { 'x-company-id': companyId },
//     })
//   return response.data
// }

export interface CreateCategoryRequest {
  name: string;
  priority: number;
}

export const createCategory = async (
  shop: Shop,
  createCategoryRequest: CreateCategoryRequest,
): Promise<Category> => {
  const createdCategory: Category = {
    ...createCategoryRequest,
    category_id: uuidv4(),
  };
  const currentCategories = shop.categories;
  await request.patch(
    `shops/${shop.shop_id}`,
    { categories: [...currentCategories, createdCategory] },
    { headers: { "x-company-id": shop.company_id } },
  );
  return createdCategory;
};

export const updateCategory = async (
  shop: Shop,
  categoryId: string,
  updateValue: Partial<Category>,
): Promise<Category> => {
  const currentCategories = shop.categories;
  const targetCategory = currentCategories.find(
    cat => cat.category_id === categoryId,
  );
  if (!targetCategory) throw new Error("no such category");
  const updatedCategory: Category = { ...targetCategory, ...updateValue };
  const updatedCategories = currentCategories.map(cat =>
    cat.category_id === categoryId ? updatedCategory : cat,
  );

  await request.patch(
    `shops/${shop.shop_id}`,
    { categories: updatedCategories },
    { headers: { "x-company-id": shop.company_id } },
  );

  return updatedCategory;
};

export interface CreateMenuTypeRequest {
  name: string;
  priority: number;
  type: MenuConditionType;
  condition: MenuCondition;
}

export const createMenuType = async (
  shop: Shop,
  createMenuTypeRequest: CreateMenuTypeRequest,
): Promise<MenuType> => {
  const createdMenuType: MenuType = {
    ...createMenuTypeRequest,
    menu_type_id: uuidv4(),
  };
  const currentMenuTypes = shop.menu_types;
  await request.patch(
    `shops/${shop.shop_id}`,
    { menu_types: [...currentMenuTypes, createdMenuType] },
    { headers: { "x-company-id": shop.company_id } },
  );
  return createdMenuType;
};

export const updateMenuType = async (
  shop: Shop,
  menuTypeId: string,
  updateValue: Partial<MenuType>,
): Promise<MenuType> => {
  const currentMenuTypes = shop.menu_types;
  const targetMenuType = currentMenuTypes.find(
    mt => mt.menu_type_id === menuTypeId,
  );
  if (!targetMenuType) throw new Error("no such menu type");
  const updatedMenuType: MenuType = { ...targetMenuType, ...updateValue };
  const updatedMenuTypes = currentMenuTypes.map(mt =>
    mt.menu_type_id === menuTypeId ? updatedMenuType : mt,
  );

  await request.patch(
    `shops/${shop.shop_id}`,
    { menu_types: updatedMenuTypes },
    { headers: { "x-company-id": shop.company_id } },
  );
  return updatedMenuType;
};

export interface GetOrdersQuery {
  order_status?: OrderStatus;
  is_read?: boolean;
  ordered_at?: number;
  updated_at?: number;
  visit_at?: number;
  date?: string;
}

export const queryOrders = async (
  shop: Shop,
  query: GetOrdersQuery,
): Promise<Order[]> => {
  const queryOrdersResponse = await request.get(
    `shops/${shop.shop_id}/orders`,
    { params: query, headers: { "x-company-id": shop.company_id } },
  );
  return queryOrdersResponse.data;
};

export const runSlackOrderPost = async (order: Order): Promise<void> => {
  const date = moment(order.visit_at * 1000)
    .tz("Asia/Tokyo")
    .format("YYYY-MM-DD");
  await request.get(
    `admin/shops/${order.shop_id}/slack_post?order_id=${order.order_id}&date=${date}`,
    { headers: { "x-company-id": order.company_id } },
  );
};

export const runSlackOrdersInDayPost = async (
  companyId: string,
  shopId: string,
  date: string,
): Promise<void> => {
  await request.get(`admin/shops/${shopId}/slack_post?date=${date}`, {
    headers: { "x-company-id": companyId },
  });
};

export const updateCharge = async (
  order: Order,
  amount: number,
  cancelDescription: string,
): Promise<void> => {
  const workDay = moment(order.visit_at * 1000)
    .tz("Asia/Tokyo")
    .format("YYYY-MM-DD");
  await request.post(
    `admin/shops/${order.shop_id}/orders/${order.order_id}/update_charge?date=${workDay}`,
    { amount, cancel_description: cancelDescription },
    { headers: { "x-company-id": order.company_id } },
  );
};

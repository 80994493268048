import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  createStyles,
  makeStyles,
  Theme,
  Button,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { CreateCompanyRequest, createCompany } from "../../../plugins/requests";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: 800,
      },
    },
  }),
);
// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CreateCompanyDialog: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [ownerId, setOwnerId] = useState("");
  const [domainPrefix, setDomainPrefix] = useState("");
  const [naviImageUrl, setNaviImageUrl] = useState("https://");
  const [stripePlatformId, setStripePlatformId] = useState("dinii");
  const [liffId, setLiffId] = useState(null as null | string);

  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const create = () => {
    const createShopRequest: CreateCompanyRequest = {
      owner_id: ownerId,
      company_id: companyId,
      name,
      company_name: companyName,
      domain_prefix: domainPrefix,
      navi_image_url: naviImageUrl,
      platform_id: stripePlatformId,
      liff_id: liffId ? liffId : undefined,
    };
    createCompany(companyId, createShopRequest)
      .then(() => {
        setDialogMessage("update success");
      })
      .catch(err => {
        setDialogMessage(JSON.stringify(err));
      });
  };

  return (
    <>
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle id="scroll-dialog-title">Company作成</DialogTitle>
        <DialogContent>
          <form className={classes.root} autoComplete="off">
            <div>
              <TextField
                required
                id="company-name-input"
                label="企業名"
                value={name}
                variant="filled"
                type="string"
                onChange={ev => setName(ev.target.value)}
              />

              <TextField
                required
                id="name-input"
                label="アプリ表題"
                value={companyName}
                variant="filled"
                type="string"
                onChange={ev => setCompanyName(ev.target.value)}
              />

              <TextField
                required
                id="owner-input"
                label="OwnerId"
                value={ownerId}
                variant="filled"
                type="string"
                onChange={ev => setOwnerId(ev.target.value)}
              />

              <TextField
                required
                id="company-id-input"
                label="CompanyId"
                value={companyId}
                variant="filled"
                type="string"
                onChange={ev => setCompanyId(ev.target.value)}
              />

              <TextField
                required
                id="domain-prefix-input"
                label="domain prefix"
                variant="filled"
                value={domainPrefix}
                type="string"
                onChange={ev => setDomainPrefix(ev.target.value)}
              />

              <TextField
                required
                id="navi-image-input"
                label="navi image url"
                variant="filled"
                value={naviImageUrl}
                type="string"
                onChange={ev => setNaviImageUrl(ev.target.value)}
              />

              <TextField
                required
                id="stripe-platform-id-input"
                label="stripe platform id"
                variant="filled"
                value={stripePlatformId}
                type="string"
                onChange={ev => setStripePlatformId(ev.target.value)}
              />

              <TextField
                required
                id="liff-id-input"
                label="LIFF id"
                variant="filled"
                value={liffId}
                type="string"
                onChange={ev => setLiffId(ev.target.value)}
              />
            </div>

            <div>
              <Button type="button" onClick={create}>
                Apply
              </Button>
              <Button onClick={onClose}> close </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default CreateCompanyDialog;

import { Category } from "./category.entity";
import { MenuType } from "./menu_type.entity";

export enum SummaryType {
  PER_MENU = "per_menu",
  PER_ORDER = "per_order",
}

export interface Fee {
  name: string;
  type: string;
  value: number;
  start?: string;
  end?: string;
}

export enum OrderType {
  TAKEOUT = "takeout",
  DELIVERY = "delivery",
}

export interface Shop {
  company_id: string;

  shop_id: string;

  name: string;

  open: string;

  close: string;

  max_num: number;

  is_display: boolean;

  is_open: boolean;

  close_text: string;

  ex_tax: boolean;

  tax_rate: number;

  top_image_url: string;

  navi_image_url: string;

  map_image_url: string;

  categories: Category[];

  menu_types: MenuType[];

  meta?: object;

  delivery_scope_zip_codes?: string[];

  enable_takeout: boolean;

  enable_delivery: boolean;

  enable_stripe: boolean;

  enable_on_site_payment: boolean;

  slack_post_channel?: string;

  owner_id: string;

  ready_time: number;

  delivery_ready_time: number;

  delivery_fee?: number;

  order_summary_type: SummaryType;

  priority: number;

  delivery_visit_date_length: number;

  takeout_visit_date_length: number;

  is_show_how_to_get_item: boolean;

  require_nick_name: boolean;

  print_ready_time: number;

  takeout_slot_step: number;

  delivery_slot_step: number;

  disable_remark?: boolean;
}

import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Auth from "./presentation/Auth";
import {
  MainContextInterface,
  initialMainContext,
  MainContextProvider,
} from "./context/MainContext";

const App: React.FC = () => {
  const [mainContext, setMainContext] = useState<MainContextInterface>(
    initialMainContext,
  );
  return (
    <BrowserRouter>
      <MainContextProvider value={[mainContext, setMainContext]}>
        <Auth />
      </MainContextProvider>
    </BrowserRouter>
  );
};

export default App;

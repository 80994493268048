import React, { useEffect, useState, useCallback } from "react";
import {
  Checkbox,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from "@material-ui/core";
import firebase from "../../plugins/firebase";
import { green, lightBlue, yellow, deepOrange } from "@material-ui/core/colors";
import ConnectionLogsDialog from "./ConnectionLogsDialog/ConnectionLogsDialog";
import * as request from "../../plugins/requests";
import { Shop } from "../../entity/shop.entity";
import { Company } from "../../entity/company.entity";
import { getCompanies } from "../../plugins/requests";
import clsx from "clsx";
const Moment = require("moment");

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  oneMinCell: {
    backgroundColor: lightBlue.A700,
    color: "white",
  },
  threeMinCell: {
    backgroundColor: green.A700,
    color: "white",
  },
  fiveMinCell: {
    backgroundColor: yellow["500"],
  },
  overTenMinCell: {
    backgroundColor: deepOrange["600"],
    color: "white",
  },
  over24MinCell: {
    backgroundColor: "white",
    color: "black",
  },
});

enum LogType {
  startOperation = "startOperation",
  waitingHome = "waitingHome",
  reboot = "reboot",
}

interface ConnectionStatus {
  lastUpdatedAt: any;
  lastUpdatedType: LogType;
  companyId: string;
  shopId: string;
  shopClientId: string;
}

const ConnectionStatus: React.FC = () => {
  const classes = useStyles();
  const [statuses, setStatuses] = useState([] as ConnectionStatus[]);
  const [selectedStatus, setSelectedStatus] = useState({} as ConnectionStatus);
  const [logModalOpen, setLogModalOpen] = useState(false);
  const [shops, setShops] = useState([] as Shop[]);
  const [companies, setCompanies] = useState([] as Company[]);

  const [filterCompanyIds, setFilterCompanyIds] = useState([] as string[]);

  const filterdLogs = useCallback(
    () =>
      statuses.filter(status =>
        filterCompanyIds.length === 0
          ? statuses
          : filterCompanyIds.includes(status.companyId),
      ),
    [filterCompanyIds, statuses],
  );

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("connection-status")
      .orderBy("lastUpdatedAt", "desc")
      .onSnapshot(
        snapshot => {
          console.log("get query");
          const logs = snapshot.docs.map(value => {
            return value.data();
          }) as ConnectionStatus[];

          // FIXME: adminの取得もログに残ってしまうため，一旦ここでフィルター
          setStatuses(
            logs.filter(
              log =>
                ![firebase.auth().currentUser?.uid, "admin"].includes(
                  log.shopClientId,
                ),
            ),
          );
        },
        err => {
          console.log(`Encountered error: ${err}`);
        },
      );
    return unsubscribe;
  }, []);

  useEffect(() => {
    getCompanies().then(companies => {
      setCompanies([...companies]);
    });
  }, []);

  useEffect(() => {
    companies.forEach(company => {
      request.getShops(company.company_id).then(shops => {
        setShops(old => [...old, ...shops]);
      });
    });
  }, [companies]);

  const getShopName = (shopId: string): string => {
    const ret = shops.find(shop => shop.shop_id === shopId);
    return ret?.name || "";
  };

  const getCompanyName = (companyId: string): string => {
    const ret = companies.find(company => company.company_id === companyId);
    return ret?.name || "";
  };

  const getCellClassFromUpdatedAt = (date: Date) => {
    const updatedUnix = Moment(date).unix();
    const now = Moment().unix();
    const diff = now - updatedUnix;
    if (diff < 60) {
      return classes.oneMinCell;
    } else if (diff < 180) {
      return classes.threeMinCell;
    } else if (diff < 300) {
      return classes.fiveMinCell;
    } else if (diff < 24 * 60 * 60) {
      return classes.overTenMinCell;
    } else {
      return classes.over24MinCell;
    }
  };

  return (
    <>
      <Paper>
        <div style={{ display: "flex" }}>
          <div>
            <Typography variant="h5">CompanyIdでフィルター</Typography>
            <List
              dense
              style={{ width: "300px", overflow: "auto", maxHeight: 300 }}
            >
              {companies.map(({ company_id }, index) => (
                <ListItem key={index} button>
                  <ListItemText id={`${index}`} primary={company_id} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      onChange={() => {
                        if (filterCompanyIds.includes(company_id)) {
                          setFilterCompanyIds(prev =>
                            prev.filter(v => v !== company_id),
                          );
                        } else {
                          setFilterCompanyIds(prev => [...prev, company_id]);
                        }
                      }}
                      checked={filterCompanyIds.includes(company_id)}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>

          <div>
            <Typography variant="h5">ステータス凡例</Typography>
            <span className={classes.oneMinCell}>1分以内</span>
            <span className={classes.threeMinCell}>3分以内</span>
            <span className={classes.fiveMinCell}>5分以内</span>
            <span className={classes.overTenMinCell}>10分~24時間以内</span>
          </div>
        </div>

        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Company ID</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">Shop Client ID</TableCell>
              <TableCell align="right">Last updated at</TableCell>
              <TableCell align="right">Last updated type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterdLogs().map((status, index) => (
              <TableRow
                key={index}
                onClick={() => {
                  setSelectedStatus(status);
                  setLogModalOpen(true);
                }}
              >
                <TableCell component="th" scope="row">
                  {status.companyId}
                </TableCell>
                <TableCell component="th" scope="row">
                  {getCompanyName(status.companyId)}
                </TableCell>
                <TableCell align="right">{status.shopId}</TableCell>
                <TableCell align="right">
                  {getShopName(status.shopId)}
                </TableCell>
                <TableCell align="right">{status.shopClientId}</TableCell>
                <TableCell
                  align="right"
                  className={clsx(
                    getCellClassFromUpdatedAt(status.lastUpdatedAt?.toDate()),
                  )}
                >
                  {formatDate(status.lastUpdatedAt.toDate())}
                </TableCell>
                <TableCell align="right">{status.lastUpdatedType}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <ConnectionLogsDialog
        companyId={selectedStatus.companyId}
        shopId={selectedStatus.shopId}
        shopClientId={selectedStatus.shopClientId}
        isOpen={logModalOpen}
        onClose={() => setLogModalOpen(false)}
      />
    </>
  );
};

const formatDate = (date: Date) => {
  return Moment(date).format("YYYY-MM-DD HH:mm:ss");
};

export default ConnectionStatus;

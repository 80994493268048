import React from "react";
import { Switch, Button, TextField, Grid } from "@material-ui/core";
import { Option } from "../../../../../entity/option.entity";
import { Choice } from "../../../../../entity/choice.entity";
import { ChoiceIdEditorDialog } from "./ChoiceIdsEditor";

export interface Props {
  option: Option;
  updateOption: (updatedValues: Partial<Option>) => Promise<void>;
  choices: Choice[];
  openChoiceIdsEditor: boolean;
  setOpenChoiceIdsEditor: (val: boolean) => void;
}

export const OptionComponent: React.FC<Props> = ({
  option,
  updateOption,
  choices,
  openChoiceIdsEditor,
  setOpenChoiceIdsEditor,
}: Props) => {
  let updateOptionValue: Partial<Option> = {};

  const applyChange = async () => {
    await updateOption(updateOptionValue);
    updateOptionValue = {};
  };

  const updateChoiceIds = async (
    option: Option,
    choiceId: string,
    updateValue: boolean,
  ) => {
    if (!option) return;

    if (option.choice_ids?.includes(choiceId) && !updateValue) {
      const updatedChoiceIds = option.choice_ids.filter(id => id !== choiceId);
      await updateOption({ choice_ids: updatedChoiceIds });
    } else if (!option.choice_ids?.includes(choiceId) && updateValue) {
      const updatedChoiceIds = [...option.choice_ids!, choiceId];
      await updateOption({ choice_ids: updatedChoiceIds });
    }
  };

  // const deleteProperty = async (key: keyof Option) => {
  //   await updateOption({ description: undefined })
  //   updateOptionValue = {}
  // }

  return (
    <div
      style={{
        margin: "10px",
        padding: "10px",
        background: "white",
        border: "solid 1px black",
      }}
    >
      <h2>オプション</h2>
      <Grid container spacing={3}>
        <Grid item xs={1} sm={1}>
          <p>option id: {option.option_id}</p>
        </Grid>

        <Grid item xs={3} sm={3}>
          <p>オプション名: {option.name}</p>
          <p>
            <TextField
              style={{ width: "100%" }}
              defaultValue={option.name}
              type="string"
              id="option-name-input"
              label="オプション名"
              variant="filled"
              onChange={ev => {
                updateOptionValue["name"] = ev.target.value;
              }}
            />
          </p>
          <p>
            <Button onClick={() => applyChange()}>apply</Button>
          </p>
        </Grid>

        <Grid item>
          <p>表示・非表示設定:</p>
          <Switch
            required
            checked={option.is_display}
            onChange={() =>
              updateOption({
                is_display: !option.is_display,
              })
            }
          />
        </Grid>

        <Grid item>
          <p>最大のチョイス数: {option.max_choice_num}</p>
          <p>
            <TextField
              defaultValue={option.max_choice_num}
              type="number"
              id="filled-basic"
              label="最大のチョイス数"
              variant="filled"
              onChange={ev => {
                updateOptionValue["max_choice_num"] = Number(ev.target.value);
              }}
            />
          </p>
          <p>
            <Button onClick={() => applyChange()}>Apply</Button>
          </p>
        </Grid>

        <Grid item>
          <p>最小のチョイス数: {option.min_choice_num}</p>
          <p>
            <TextField
              defaultValue={option.min_choice_num}
              type="number"
              id="filled-basic"
              label="最小のチョイス数"
              variant="filled"
              onChange={ev => {
                updateOptionValue["min_choice_num"] = Number(ev.target.value);
              }}
            />
          </p>
          <p>
            <Button onClick={() => applyChange()}>Apply</Button>
          </p>
        </Grid>

        <Grid item>
          <p>チョイスの説明: {option.description}</p>
          <p>
            <TextField
              defaultValue={option.description}
              type="string"
              id="filled-basic"
              label="チョイスの説明"
              variant="filled"
              onChange={ev => {
                updateOptionValue["description"] = ev.target.value;
              }}
            />
          </p>
          <p>
            <Button onClick={() => applyChange()}>Apply</Button>
            {/* <Button onClick={() => deleteProperty('description')}>Delete</Button> */}
          </p>
        </Grid>
        <Grid>
          <Button
            variant="outlined"
            color="primary"
            style={{ width: "20vw", marginBottom: "30px" }}
            onClick={() => setOpenChoiceIdsEditor(true)}
          >
            チョイスの追加
          </Button>

          <ChoiceIdEditorDialog
            isOpen={openChoiceIdsEditor}
            option={option}
            choices={choices}
            updateValue={(choiceId, value) => {
              updateChoiceIds(option, choiceId, value);
            }}
            onClose={() => setOpenChoiceIdsEditor(false)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

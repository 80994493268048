import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  createStyles,
  makeStyles,
  Theme,
  Button,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import {
  createMenuType,
  CreateMenuTypeRequest,
} from "../../../plugins/requests";
import { Shop } from "../../../entity/shop.entity";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: 800,
      },
    },
  }),
);
// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface Props {
  shop: Shop;
  isOpen: boolean;
  onClose: () => void;
  reloadShops: () => void;
}

const CreateMenuTypeDialog: React.FC<Props> = ({
  shop,
  isOpen,
  onClose,
  reloadShops,
}: Props) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [priority, setPriority] = useState(0);

  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const create = () => {
    const createMenuTypeRequest: CreateMenuTypeRequest = {
      name,
      priority,
      type: "food",
      condition: { start: "04:00", end: "23:59" },
    };
    createMenuType(shop, createMenuTypeRequest)
      .then(() => {
        setDialogMessage("update success");
        reloadShops();
      })
      .catch(err => {
        setDialogMessage(JSON.stringify(err));
      });
  };

  return (
    <>
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle id="scroll-dialog-title">メニュータイプ作成</DialogTitle>
        <DialogContent>
          <form className={classes.root} autoComplete="off">
            <div>
              <TextField
                required
                id="name-input"
                label="メニュータイプ名"
                value={name}
                variant="filled"
                type="string"
                onChange={ev => setName(ev.target.value)}
              />

              <TextField
                required
                id="priority-input"
                label="Priority"
                variant="filled"
                value={priority}
                type="numebr"
                onChange={ev => setPriority(Number(ev.target.value))}
              />
            </div>

            <div>
              <Button type="button" onClick={create}>
                作成
              </Button>
              <Button onClick={onClose}> 閉じる </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default CreateMenuTypeDialog;

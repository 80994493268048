import React, { useEffect, useState } from "react";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Paper,
  // eslint-disable-next-line no-unused-vars
  Theme,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { Choice } from "../../../entity/choice.entity";
import * as request from "../../../plugins/requests";
import { ChoiceComponent } from "../menus/OptionDialog/components/ChoiceComponent";
import CreateChoiceDialog from "./CreateChoiceDialog";
import { ShopSelection } from "../../components/shopSelection";
import { useShops } from "../../../hooks/useShops";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    form: {
      "& > *": {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
    list: {
      maxHeight: "250px",
      width: "280px",
      overflow: "auto",
    },
  }),
);

const Choices: React.FC = () => {
  const classes = useStyles();

  const { shops, companies, selectedShop, setSelectedShop } = useShops();
  const [choices, setChoices] = useState([] as Choice[]);

  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const [createChoiceModalOpen, setCreateChoiceModalOpen] = useState(false);

  const updateChoice = async <K extends keyof Choice>(
    choiceId: string,
    updateValue: Partial<Choice>,
  ): Promise<void> => {
    try {
      const targetChoice = choices.find(
        choice => choice.choice_id === choiceId,
      );
      if (!targetChoice) return;
      const updatedChoice = await request.updateChoice(
        targetChoice.company_id,
        targetChoice.shop_id,
        targetChoice.choice_id,
        updateValue,
      );
      setChoices(
        choices.map(existingChoice =>
          existingChoice.choice_id === updatedChoice.choice_id
            ? updatedChoice
            : existingChoice,
        ),
      );
      setDialogMessage("update success");
    } catch (err) {
      setDialogMessage(JSON.stringify(err, null, 2));
    }
  };

  const selectShop = (shopId: string) => {
    const shop = shops.find(_shop => _shop.shop_id === shopId);
    if (!shop) return;
    const company = companies.find(
      _company => _company.company_id === shop.company_id,
    );
    if (!company) return;
    setSelectedShop(shop);
  };

  useEffect(() => {
    if (!selectedShop) return;
    request
      .getChoices(selectedShop.company_id, selectedShop.shop_id)
      .then(choices => {
        setChoices([...choices]);
      });
  }, [selectedShop]);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        style={{ width: "70vw", marginBottom: "30px" }}
        onClick={() => setCreateChoiceModalOpen(true)}
      >
        チョイスを作成する
      </Button>

      <ShopSelection
        selectedShop={selectedShop}
        shops={shops}
        selectShop={selectShop}
      />

      <Paper>
        {selectedShop && (
          <ChoiceComponent
            shop={selectedShop}
            tableClassName={classes.table}
            choices={choices}
            updateChoice={(choiceId, updateValue) =>
              updateChoice(choiceId, updateValue)
            }
          />
        )}
      </Paper>

      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>

      {selectedShop && (
        <CreateChoiceDialog
          shop={selectedShop}
          isOpen={createChoiceModalOpen}
          onClose={() => setCreateChoiceModalOpen(false)}
        />
      )}
    </>
  );
};

export default Choices;

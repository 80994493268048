import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  createStyles,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // eslint-disable-next-line no-unused-vars
  Theme,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { Shop } from "../../../entity/shop.entity";
import { Menu } from "../../../entity/menu.entity";
import { Category } from "../../../entity/category.entity";
import { MenuType } from "../../../entity/menu_type.entity";
import * as request from "../../../plugins/requests";
import { CreateMenuRequest } from "../../../plugins/requests";
import uuidv4 from "uuid/v4";
import { useShops } from "../../../hooks/useShops";

const deleteAllData = async (shop: Shop): Promise<void> => {
  // delete menus
  const menus = await request.getMenus(shop.company_id, shop.shop_id, false);
  await Promise.all(
    menus.map(menu =>
      request.deleteMenu(menu.company_id, menu.shop_id, menu.menu_id),
    ),
  );

  // delete options
  const options = await request.getOptions(shop.company_id, shop.shop_id);
  await Promise.all(
    options.map(option =>
      request.deleteOption(option.company_id, option.shop_id, option.option_id),
    ),
  );

  // delete choices
  const choices = await request.getChoices(shop.company_id, shop.shop_id);
  await Promise.all(
    choices.map(choice =>
      request.deleteChoice(choice.company_id, choice.shop_id, choice.choice_id),
    ),
  );

  // delete categories and menuTypes
  await request.updateShop(shop.company_id, shop.shop_id, {
    categories: [],
    menu_types: [],
  });
};

const runCopy = async (
  srcShop: Shop,
  dstShop: Shop,
  menus: Menu[],
  categories: Category[],
  menuTypes: MenuType[],
) => {
  const categoryIdsMap = categories.map(category => [
    category.category_id,
    uuidv4(),
  ]);

  const menuTypeIdsMap = menuTypes.map(menuType => [
    menuType.menu_type_id,
    uuidv4(),
  ]);

  const convert = (map: string[][], query: string): string => {
    try {
      return map.filter(elem => elem[0] === query)[0][1];
    } catch (err) {
      console.error("map error:", err, map, query);
      throw err;
    }
  };

  await request.updateShop(dstShop.company_id, dstShop.shop_id, {
    categories: categories.map(category => ({
      ...category,
      category_id: convert(categoryIdsMap, category.category_id),
    })),
    menu_types: menuTypes.map(menuType => ({
      ...menuType,
      menu_type_id: convert(menuTypeIdsMap, menuType.menu_type_id),
    })),
  });

  const choices = await request.getChoices(srcShop.company_id, srcShop.shop_id);
  const options = await request.getOptions(srcShop.company_id, srcShop.shop_id);

  const choiceIdsMap = await Promise.all(
    choices.map(async choice => {
      const createdChoice = await request.createChoice(
        dstShop.company_id,
        dstShop.shop_id,
        {
          name: choice.name,
          priority: choice.priority,
          price: choice.price,
          shop_display_name: choice.shop_display_name || choice.name,
        },
      );
      return [choice.choice_id, createdChoice.choice_id];
    }),
  );

  const optionIdsMap = await Promise.all(
    options.map(async option => {
      const createdOption = await request.createOption(
        dstShop.company_id,
        dstShop.shop_id,
        {
          name: option.name,
          priority: option.priority,
          max_choice_num: option.max_choice_num,
          min_choice_num: option.min_choice_num,
          is_required: option.is_required,
          is_multiple: option.is_multiple,
          choice_ids: option.choice_ids.map(choiceId =>
            convert(choiceIdsMap, choiceId),
          ),
        },
      );
      return [option.option_id, createdOption.option_id];
    }),
  );

  for (const menu of menus) {
    const createBody: CreateMenuRequest = {
      name: menu.name,
      price: menu.price,
      priority: menu.priority,
      category_id: convert(categoryIdsMap, menu.category_id),
      menu_type_id: convert(menuTypeIdsMap, menu.menu_type_id),
      description: menu.description,
      image_url: menu.image_url,
      max_num: menu.max_num,
      shop_display_name: menu.shop_display_name,
      option_ids:
        menu.options?.map(opt => convert(optionIdsMap, opt.option_id)) || [],
    };
    await request.createMenu(dstShop, createBody);
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    form: {
      "& > *": {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
    list: {
      maxHeight: "250px",
      width: "280px",
      overflow: "auto",
    },
  }),
);

const CopyMenus: React.FC = () => {
  const classes = useStyles();

  const { companies, shops, selectedShop, setSelectedShop } = useShops();

  const [toBeCopiedShop, setToBeCopiedShop] = useState(null as Shop | null);
  const [menus, setMenus] = useState([] as Menu[]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [msg, setMsg] = useState(null as string | null);

  const categories = useCallback(() => selectedShop?.categories || [], [
    selectedShop,
  ]);

  const menuTypes = useCallback(() => selectedShop?.menu_types || [], [
    selectedShop,
  ]);

  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const run = async () => {
    if (!selectedShop || !toBeCopiedShop) return;
    setIsProcessing(true);
    setMsg("");
    try {
      await runCopy(
        selectedShop,
        toBeCopiedShop,
        menus,
        categories(),
        menuTypes(),
      );
      setMsg("コピーが完了しました");
    } catch (err) {
      setMsg("コピーに失敗しました");
    } finally {
      setIsProcessing(false);
    }
  };

  const runAfterDeleteAll = async () => {
    if (!selectedShop || !toBeCopiedShop) return;
    setIsProcessing(true);
    setMsg("");
    try {
      await deleteAllData(toBeCopiedShop);
      setMsg("データ削除が完了しました。データのコピーをしています");

      await runCopy(
        selectedShop,
        toBeCopiedShop,
        menus,
        categories(),
        menuTypes(),
      );
      setMsg("コピーが完了しました");
    } catch (err) {
      setMsg("コピーに失敗しました");
    } finally {
      setIsProcessing(false);
    }
  };

  const selectShop = (shopId: string) => {
    const shop = shops.find(_shop => _shop.shop_id === shopId);
    if (!shop) return;
    const company = companies.find(
      _company => _company.company_id === shop.company_id,
    );
    if (!company) return;
    setSelectedShop(shop);
  };

  const selectToBeCopiedShop = (shopId: string) => {
    const shop = shops.find(_shop => _shop.shop_id === shopId);
    if (!shop) return;
    const company = companies.find(
      _company => _company.company_id === shop.company_id,
    );
    if (!company) return;
    setToBeCopiedShop(shop);
  };

  useEffect(() => {
    if (!selectedShop) return;
    setMenus([]);
    request
      .getMenus(selectedShop.company_id, selectedShop.shop_id)
      .then(menus => {
        setMenus(prevState => [...prevState, ...menus]);
      });
  }, [selectedShop]);

  return (
    <>
      <FormControl className={classes.formControl} style={{ width: "300px" }}>
        <InputLabel id="shop-selection-label">コピー元店舗を選択</InputLabel>
        <Select
          labelId="shop-selection-label"
          id="shop-selection"
          value={selectedShop && selectedShop.shop_id}
          onChange={ev => selectShop(ev.target.value as string)}
        >
          {shops.map(shop => (
            <MenuItem
              value={shop.shop_id}
              key={shop.shop_id}
            >{`${shop.company_id}/${shop.name}`}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl} style={{ width: "300px" }}>
        <InputLabel id="shop-selection-label">コピー先店舗を選択</InputLabel>
        <Select
          labelId="shop-selection-label"
          id="shop-selection"
          value={toBeCopiedShop && toBeCopiedShop.shop_id}
          onChange={ev => selectToBeCopiedShop(ev.target.value as string)}
        >
          {shops.map(shop => (
            <MenuItem
              value={shop.shop_id}
              key={shop.shop_id}
            >{`${shop.company_id}/${shop.name}`}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        color="primary"
        variant="contained"
        style={{ width: "250px" }}
        onClick={() => {
          if (window.confirm("よろしいですか？")) {
            run();
          }
        }}
        disabled={isProcessing}
      >
        コピー先の店舗の既存データを残して，コピーする
      </Button>
      <Button
        color="secondary"
        variant="contained"
        style={{ width: "250px", marginLeft: "30px" }}
        onClick={() => {
          if (window.confirm("よろしいですか？")) {
            runAfterDeleteAll();
          }
        }}
        disabled={isProcessing}
      >
        コピー先の店舗の既存データを削除して，コピーする
      </Button>
      {isProcessing && <CircularProgress />}
      {msg || ""}

      <Paper>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "20px" }} align="left">
                Company ID
              </TableCell>
              <TableCell style={{ width: "20px" }} align="right">
                Shop ID
              </TableCell>
              <TableCell style={{ width: "20px" }} align="right">
                Shop Name
              </TableCell>
              <TableCell align="right">MenuID</TableCell>
              <TableCell align="right">メニュー名</TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell align="right">カテゴリ名</TableCell>
              <TableCell align="right">メニュータイプ名</TableCell>
              <TableCell align="right">値段</TableCell>
              <TableCell align="right">在庫上限</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {menus.map((menu, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {menu.company_id}
                </TableCell>
                <TableCell align="right">{menu.shop_id}</TableCell>
                <TableCell align="right">{selectedShop?.name || ""}</TableCell>

                <TableCell align="right">{menu.menu_id}</TableCell>

                <TableCell align="right">{menu.name}</TableCell>

                <TableCell align="right">{menu.description || ""}</TableCell>

                <TableCell align="right">
                  <p>
                    <span>
                      {categories().find(
                        cat => cat.category_id === menu.category_id,
                      )?.name || "なし"}
                    </span>
                  </p>
                </TableCell>

                <TableCell align="right">
                  <p>
                    <span>
                      {menuTypes().find(
                        mt => mt.menu_type_id === menu.menu_type_id,
                      )?.name || "なし"}
                    </span>
                  </p>
                </TableCell>

                <TableCell align="right">{`${menu.price}`}</TableCell>

                <TableCell align="right">
                  {menu.image_url}
                  {menu.image_url && menu.image_url !== "" && (
                    <div>
                      <img src={menu.image_url} width={50} height={50} alt="" />
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default CopyMenus;

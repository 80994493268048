import React from "react";
import {
  Checkbox,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Option } from "../../../../../entity/option.entity";
import { Choice } from "../../../../../entity/choice.entity";

export interface ChoiceIdEditorProps {
  isOpen: boolean;
  option: Option;
  choices: Choice[];
  updateValue: (choiceId: string, value: boolean) => void;
  onClose: () => void;
}

export const ChoiceIdEditorDialog: React.FC<ChoiceIdEditorProps> = ({
  option,
  isOpen,
  choices,
  onClose,
  updateValue,
}: ChoiceIdEditorProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <List dense>
          {choices.map((choice, index) => {
            return (
              <ListItem key={index} button>
                <ListItemText
                  id={`${choice.choice_id}`}
                  primary={choice.choice_id + "/" + choice.name}
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    onChange={ev => {
                      updateValue(choice.choice_id, ev.target.checked);
                    }}
                    checked={option.choice_ids?.includes(choice.choice_id)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
};

import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Shop } from "../../entity/shop.entity";
import styled from "styled-components";

interface Props {
  shops: Shop[];
  selectedShop: Shop | null;
  selectShop: (shopId: string) => void;
}

export const ShopSelection: React.FC<Props> = ({
  selectedShop,
  shops,
  selectShop,
}: Props) => (
  <MyFormControl>
    <InputLabel id="shop-selection-label">店舗を選択</InputLabel>
    <Select
      labelId="shop-selection-label"
      id="shop-selection"
      value={selectedShop && selectedShop.shop_id}
      onChange={ev => selectShop(ev.target.value as string)}
    >
      {shops
        .sort((a, b) => (a.company_id > b.company_id ? 1 : -1))
        .map(shop => (
          <MenuItem
            value={shop.shop_id}
            key={shop.shop_id}
          >{`${shop.company_id}/${shop.name}`}</MenuItem>
        ))}
    </Select>
  </MyFormControl>
);

const MyFormControl = styled(FormControl)`
  margin: 10px;
  width: 300px;
`;

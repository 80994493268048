import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  createStyles,
  makeStyles,
  Theme,
  Button,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { Order } from "../../../entity/order.entity";
import styled from "styled-components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: 800,
      },
    },
  }),
);
// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface LabelProps {
  label: string;
  value: string;
  color?: string;
}

const LabelAndText: React.FC<LabelProps> = ({
  label,
  value,
  color,
}: LabelProps) => (
  <div
    style={{ display: "flex", justifyContent: "start", alignItems: "center" }}
  >
    <div
      style={{
        marginRight: "10px",
        border: `1px solid ${color || "gray"}`,
        padding: "5px",
        width: "10vw",
      }}
    >
      {label}
    </div>
    <div>{value}</div>
  </div>
);

interface Props {
  order: Order;
  isOpen: boolean;
  onClose: () => void;
}

const OrderDetailDialog: React.FC<Props> = ({
  order,
  isOpen,
  onClose,
}: Props) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle id="scroll-dialog-title">オーダー詳細</DialogTitle>
        <DialogContent>
          <form className={classes.root} autoComplete="off">
            {order.order_menus.map(orderMenu => (
              <OrderMenuWrap>
                <LabelAndText label="menuID" value={orderMenu.menu_id} />
                <LabelAndText label="メニュー名" value={orderMenu.name} />
                <LabelAndText label="個数" value={`${orderMenu.quantity}`} />
                <LabelAndText label="単価" value={`￥${orderMenu.price}`} />
                <LabelAndText
                  label="合計金額"
                  value={`￥${orderMenu.price_all}`}
                />
                <LabelAndText label="備考" value={`${orderMenu.remark}`} />
                {orderMenu.options?.map(option => (
                  <OrderOptionWrap>
                    <LabelAndText
                      label="OptionId"
                      value={`${option.option_id}`}
                    />
                    <LabelAndText
                      label="オプション名"
                      value={`${option.name}`}
                    />
                    {option.choices.map(choice => (
                      <OrderChoiceWrap>
                        <LabelAndText
                          label="ChoiceId"
                          value={`${choice.choice_id}`}
                        />
                        <LabelAndText
                          label="チョイス名"
                          value={`${choice.name}`}
                        />
                        <LabelAndText
                          label="個数"
                          value={`${choice.quantity}`}
                        />
                        <LabelAndText
                          label="金額"
                          value={`￥${choice.price}`}
                        />
                      </OrderChoiceWrap>
                    ))}
                  </OrderOptionWrap>
                ))}
              </OrderMenuWrap>
            ))}
            <div>
              <Button onClick={onClose}> 閉じる </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const OrderMenuWrap = styled.div`
  margin: 20px;
  padding: 10px;
  background-color: #e0e0e0;
`;

const OrderOptionWrap = styled.div`
  margin: 20px;
  padding: 10px;
  background-color: lightGray;
  border: 1px solid #a0a0a0;
`;
const OrderChoiceWrap = styled.div`
  margin: 20px;
  padding: 10px;
  background-color: lightGray;
  border: 1px solid #a0a0a0;
`;

export default OrderDetailDialog;

import React, { useState, useCallback } from "react";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // eslint-disable-next-line no-unused-vars
  Theme,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import DataTableRowComponent from "../../components/dataTableRowComponent";
import CreateCategoryDialog from "./CreateCategoryDialog";
import { Category } from "../../../entity/category.entity";
import * as request from "../../../plugins/requests";
import { ShopSelection } from "../../components/shopSelection";
import { useShops } from "../../../hooks/useShops";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    form: {
      "& > *": {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
    list: {
      maxHeight: "250px",
      width: "280px",
      overflow: "auto",
    },
  }),
);

const Categories: React.FC = () => {
  const classes = useStyles();

  const {
    shops,
    companies,
    selectedShop,
    setShops,
    setSelectedShop,
  } = useShops();
  const categories = useCallback(() => selectedShop?.categories || [], [
    selectedShop,
  ]);

  // edit flags
  const [editFocusIndex, setEditFocusIndex] = useState(null as null | number);

  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const [createCategoryModalOpen, setCreateCategoryModalOpen] = useState(false);

  const updateCategory = <K extends keyof Category>(
    category: Category,
    key: K,
    value: Category[K],
  ) => {
    if (!selectedShop) return;
    request
      .updateCategory(selectedShop, category.category_id, { [key]: value })
      .then(() => {
        reloadShops();
        setDialogMessage("update success");
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
    setEditFocusIndex(null);
  };

  const reloadShops = () => {
    setShops([]);
    companies.forEach(company => {
      request.getShops(company.company_id).then(shops => {
        setShops([...shops, ...shops]);
        const targetShop = shops.find(
          shop => shop.shop_id === selectedShop?.shop_id,
        );
        if (targetShop) {
          setSelectedShop(targetShop);
        }
      });
    });
  };

  const selectShop = (shopId: string) => {
    const shop = shops.find(_shop => _shop.shop_id === shopId);
    if (!shop) return;
    const company = companies.find(
      _company => _company.company_id === shop.company_id,
    );
    if (!company) return;
    setSelectedShop(shop);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        style={{ width: "70vw", marginBottom: "30px" }}
        onClick={() => setCreateCategoryModalOpen(true)}
      >
        カテゴリを作成する
      </Button>

      <ShopSelection
        selectedShop={selectedShop}
        shops={shops}
        selectShop={selectShop}
      />

      <Paper>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">Category Id</TableCell>
              <TableCell align="right">カテゴリ名</TableCell>
              <TableCell align="right">表示順(低いほど上に表示)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories().map((category, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {selectedShop?.company_id || ""}
                </TableCell>
                <TableCell align="right">
                  {selectedShop?.shop_id || ""}
                </TableCell>
                <TableCell align="right">{selectedShop?.name || ""}</TableCell>
                <TableCell align="right">{category.category_id}</TableCell>
                <TableCell align="right">
                  {category.name || ""}
                  {DataTableRowComponent({
                    defaultValue: category.name,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      updateCategory(category, "name", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>
                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: category.priority,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      updateCategory(category, "priority", Number(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "number",
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>

      {selectedShop && (
        <CreateCategoryDialog
          shop={selectedShop}
          isOpen={createCategoryModalOpen}
          onClose={() => setCreateCategoryModalOpen(false)}
          reloadShops={() => reloadShops()}
        />
      )}
    </>
  );
};

export default Categories;

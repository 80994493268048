import React, { useEffect, useState } from "react";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // eslint-disable-next-line no-unused-vars
  Theme,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import DataTableRowComponent from "../../components/dataTableRowComponent";
import CreateShopClientDialog from "./CreateShopClientDialog";
import * as request from "../../../plugins/requests";
import { ShopClient } from "../../../entity/shopClient.entity";
import { ShopSelection } from "../../components/shopSelection";
import { useShops } from "../../../hooks/useShops";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    form: {
      "& > *": {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
    list: {
      maxHeight: "250px",
      width: "280px",
      overflow: "auto",
    },
  }),
);

const ShopClients: React.FC = () => {
  const classes = useStyles();

  const { shops, companies, selectedShop, setSelectedShop } = useShops();
  const [shopClients, setShopClients] = useState([] as ShopClient[]);

  // edit flags
  const [editFocusIndex, setEditFocusIndex] = useState(null as null | number);

  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const [createShopClientModalOpen, setCreateShopClientModalOpen] = useState(
    false,
  );

  const update = <K extends keyof ShopClient>(
    shopClient: ShopClient,
    key: K,
    value: ShopClient[K],
  ) => {
    if (!selectedShop) return;
    request
      .updateShopClient(
        selectedShop.company_id,
        selectedShop.shop_id,
        shopClient.shop_client_id,
        { [key]: value },
      )
      .then(updatedShopClient => {
        setShopClients(
          shopClients.map(existingShopClient =>
            existingShopClient.shop_client_id ===
            updatedShopClient.shop_client_id
              ? updatedShopClient
              : existingShopClient,
          ),
        );
        setDialogMessage("update success");
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
    setEditFocusIndex(null);
  };

  const selectShop = (shopId: string) => {
    const shop = shops.find(_shop => _shop.shop_id === shopId);
    if (!shop) return;
    const company = companies.find(
      _company => _company.company_id === shop.company_id,
    );
    if (!company) return;
    setSelectedShop(shop);
  };

  useEffect(() => {
    if (!selectedShop) return;
    request
      .getShopClients(selectedShop.company_id, selectedShop.shop_id)
      .then(shopClients => {
        setShopClients(prevState => [...shopClients]);
      });
  }, [selectedShop]);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        style={{ width: "70vw", marginBottom: "30px" }}
        onClick={() => setCreateShopClientModalOpen(true)}
      >
        店舗アカウントを作成する
      </Button>

      <ShopSelection
        selectedShop={selectedShop}
        shops={shops}
        selectShop={selectShop}
      />

      <Paper>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="right">Shop ID</TableCell>
              <TableCell align="right">Shop Name</TableCell>
              <TableCell align="right">ShopClientId</TableCell>
              <TableCell align="right">name</TableCell>
              <TableCell align="right">description(email)</TableCell>
              <TableCell align="right">roles</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shopClients.map((shopClient, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {selectedShop?.company_id || ""}
                </TableCell>
                <TableCell align="right">
                  {selectedShop?.shop_id || ""}
                </TableCell>
                <TableCell align="right">{selectedShop?.name || ""}</TableCell>
                <TableCell align="right">{shopClient.shop_client_id}</TableCell>
                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: shopClient.name,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shopClient, "name", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>
                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: shopClient.description,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shopClient, "description", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>
                <TableCell align="right">
                  {JSON.stringify(shopClient.roles)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>

      {selectedShop && (
        <CreateShopClientDialog
          shop={selectedShop}
          isOpen={createShopClientModalOpen}
          onClose={() => setCreateShopClientModalOpen(false)}
        />
      )}
    </>
  );
};

export default ShopClients;

import React, { useEffect } from "react";
import {
  AppBar,
  Button,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  ConnectionRoute,
  DeviceStatusRoute,
  router,
  CompaniesRoute,
  ShopsRoute,
  ShopClientsRoute,
  SlotsRoute,
  MenusRoute,
  MenuTypesRoute,
  CategoriesRoute,
  OptionsRoute,
  ChoicesRoute,
  OrdersRoute,
  CopyMenusRoute,
} from "./Router";
import { useHistory, Route, Switch } from "react-router-dom";
import firebase from "../plugins/firebase";
import DeviceStatus from "./devicestatus/DeviceStatus";
import ConnectionStatus from "./connectionstatus/ConnectionStatus";
import Companies from "./dataManagement/companies/Companies";
import Shops from "./dataManagement/shops/Shops";
import Menus from "./dataManagement/menus/Menus";
import Options from "./dataManagement/options/Options";
import Choices from "./dataManagement/choices/Choices";
import Orders from "./dataManagement/orders/Orders";
import Categories from "./dataManagement/categories/Categories";
import MenuTypes from "./dataManagement/menuTypes/MenuTypes";
import Slots from "./dataManagement/slots/Slots";
import ShopClients from "./dataManagement/shopClients/ShopClients";
import CopyMenus from "./dataManagement/copy_menus/CopyMenus";
import PaymentManagement from "./dataManagement/paymentManagement/PaymentManagement";
import { useShops } from "../hooks/useShops";
import { PaymentManagementRoute } from "./Router";
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  userName: {
    position: "absolute",
    right: 20,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
}));

const signOut = async () => await firebase.auth().signOut();
const getUserName = (callback: (name: string) => void) => {
  firebase.auth().onAuthStateChanged(user => {
    if (user && user.email) {
      callback(user.email);
    }
  });
};

const Main: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [pageTitle, setPageTitle] = React.useState(DeviceStatusRoute.title);
  const [userName, setUserName] = React.useState("a");
  const history = useHistory();

  const { setIsInitialized } = useShops(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const toggleDrawer = (side: string, open: boolean) => (event: any) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  useEffect(() => {
    getUserName(setUserName);
    return () => {};
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {pageTitle}
          </Typography>
          <Typography className={classes.userName} variant="body2" noWrap>
            {userName}
          </Typography>
          <Button onClick={() => setIsInitialized(false)}></Button>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        open={open}
        onOpen={toggleDrawer("left", true)}
        onClose={toggleDrawer("left", false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {router.map((route, index) => (
            <div
              key={index}
              onClick={() => {
                setOpen(false);
                setPageTitle(route.title);
                history.replace(route.path);
              }}
            >
              <ListItem button key={index}>
                <ListItemText primary={route.title} />
              </ListItem>
            </div>
          ))}
        </List>
        <Divider />
        <ListItem>
          <Button style={{ width: "100%" }} onClick={signOut}>
            ログアウト
          </Button>
        </ListItem>
      </SwipeableDrawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Switch>
          <Route
            exact
            path={ConnectionRoute.path}
            component={ConnectionStatus}
          />
          <Route exact path={DeviceStatusRoute.path} component={DeviceStatus} />
          <Route exact path={CompaniesRoute.path} component={Companies} />
          <Route exact path={ShopsRoute.path} component={Shops} />
          <Route exact path={ShopClientsRoute.path} component={ShopClients} />
          <Route exact path={SlotsRoute.path} component={Slots} />
          <Route exact path={MenusRoute.path} component={Menus} />
          <Route exact path={CategoriesRoute.path} component={Categories} />
          <Route exact path={MenuTypesRoute.path} component={MenuTypes} />
          <Route exact path={OptionsRoute.path} component={Options} />
          <Route exact path={ChoicesRoute.path} component={Choices} />
          <Route exact path={OrdersRoute.path} component={Orders} />
          <Route exact path={CopyMenusRoute.path} component={CopyMenus} />
          <Route
            exact
            path={PaymentManagementRoute.path}
            component={PaymentManagement}
          />
        </Switch>
      </main>
    </div>
  );
};

export default Main;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import firebase from "../../../plugins/firebase";
import { green, red } from "@material-ui/core/colors";
import { TransitionProps } from "@material-ui/core/transitions";
const Moment = require("moment");

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  okCell: {
    backgroundColor: green.A100,
  },
  ngCell: {
    backgroundColor: red.A100,
  },
});

enum ConnectionType {
  polling = "polling",
}

interface ConnectionMonitoringLog {
  companyId: string;
  shopId: string;
  shopClientId: string;
  loggedAt: number;
  type: ConnectionType;
}

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

interface Props {
  isOpen: boolean;
  companyId?: string;
  shopId?: string;
  shopClientId?: string;
  onClose: () => void;
}

const ConnectionLogsDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const [logs, setLogs] = useState([] as ConnectionMonitoringLog[]);

  useEffect(() => {
    if (!(props.companyId && props.shopId && props.shopClientId)) {
      return undefined;
    }
    const subscribe = firebase
      .database()
      .ref("connectionmonitoring")
      .orderByChild("loggedAt")
      .limitToLast(100);
    subscribe.on("value", snapshot => {
      const logs: ConnectionMonitoringLog[] = [];
      snapshot.forEach(child => {
        const log = child.val() as ConnectionMonitoringLog;
        if (
          log.companyId === props.companyId &&
          log.shopId === props.shopId &&
          log.shopClientId === props.shopClientId
        ) {
          logs.push(log);
        }
      });
      logs.reverse();
      setLogs(logs);
    });
    return () => subscribe.off();
  }, [props.companyId, props.shopId, props.shopClientId]);

  return (
    <>
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={props.isOpen}
        onClose={props.onClose}
        onClick={props.onClose}
      >
        <DialogTitle id="scroll-dialog-title">
          {`${props.companyId}-${props.shopId}-${props.shopClientId}`}
        </DialogTitle>
        <DialogContent>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">logged at</TableCell>
                <TableCell align="left">type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log, index) => (
                <TableRow key={index}>
                  <TableCell align="left">
                    {formatDate(new Date(log.loggedAt * 1000))}
                  </TableCell>
                  <TableCell align="left">{log.type}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
};

const formatDate = (date: Date) => {
  return Moment(date).format("YYYY-MM-DD HH:mm:ss");
};

export default ConnectionLogsDialog;

import React, { useState, useCallback } from "react";
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Paper,
  // eslint-disable-next-line no-unused-vars
  Theme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { OrderType } from "../../../entity/shop.entity";
import {
  queryOrders,
  runSlackOrderPost,
  updateCharge,
} from "../../../plugins/requests";
import moment from "moment-timezone";
import {
  Order,
  PaymentMethod,
  OrderStatus,
} from "../../../entity/order.entity";
import OrderDetailDialog from "./OrderDetailDialog";
import { TextField } from "@material-ui/core";
import { ShopSelection } from "../../components/shopSelection";
import CachedIcon from "@material-ui/icons/Cached";
import {
  runSlackOrdersInDayPost,
  sendSummary,
} from "../../../plugins/requests";
import styled from "styled-components";
import { useShops } from "../../../hooks/useShops";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    timePicker: {
      width: "300px",
      display: "flex",
      margin: "10px",
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    form: {
      "& > *": {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
    list: {
      maxHeight: "250px",
      width: "280px",
      overflow: "auto",
    },

    addressLabel: {
      color: "#8f8f8f",
      display: "inline-block",
      width: "50px",
    },
  }),
);

const translateOrderKey = (key: string): string => {
  switch (key) {
    case "ordered_at":
      return "注文時刻";
    case "visit_at":
      return "来店・配送希望時刻";
    case "is_printed":
      return "印刷済";
    case "order_number":
      return "注文番号";
    case "asc":
      return "昇順";
    case "desc":
      return "降順";
    default:
      return key;
  }
};

const Orders: React.FC = () => {
  const classes = useStyles();

  const { shops, companies, selectedShop, setSelectedShop } = useShops();
  const [orders, setOrders] = useState([] as Order[]);
  const [selectedOrder, setSelectedOrder] = useState(null as Order | null);
  const [toBeCanceledOrder, setToBeCanceledOrder] = useState(
    null as Order | null,
  );

  const [queryTime, setQueryTime] = useState(null as string | null);
  const [queryKey, setQueryKey] = useState(
    "ordered_at" as "ordered_at" | "visit_at" | "order_number" | "is_printed",
  );

  const [orderSortKey, setOrderSortKey] = useState("ordered_at" as keyof Order);
  const [orderSortDirection, setOrderSortDirection] = useState(
    "desc" as "desc" | "asc",
  );

  const [cancelAmount, setCancelAmount] = useState(0);
  const [cancelDescription, setCancelDescription] = useState("");

  const allPaidAmount = useCallback(
    () => orders.reduce((acc, order) => acc + (order.paid_amount || 0), 0),
    [orders],
  );

  const allCancelAmount = useCallback(
    () => orders.reduce((acc, order) => acc + (order.refunded_amount || 0), 0),
    [orders],
  );

  const allDeliveryFeeAmount = useCallback(
    () =>
      orders
        .filter(order => order.order_type === OrderType.DELIVERY)
        .reduce((acc, order) => acc + (order.delivery_fee_amount || 0), 0),
    [orders],
  );

  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const sortedOrders = useCallback(() => {
    return orders.concat().sort((a, b) => {
      if (orderSortDirection === "desc") {
        return a[orderSortKey]! < b[orderSortKey]! ? 1 : -1;
      } else {
        return a[orderSortKey]! > b[orderSortKey]! ? 1 : -1;
      }
    });
  }, [orderSortDirection, orderSortKey, orders]);

  const selectShop = (shopId: string) => {
    const shop = shops.find(_shop => _shop.shop_id === shopId);
    if (!shop) return;
    const company = companies.find(
      _company => _company.company_id === shop.company_id,
    );
    if (!company) return;
    setSelectedShop(shop);
  };

  const reloadOrder = () => {
    if (!selectedShop) return;

    const queryUnix = queryTime
      ? moment(queryTime).unix()
      : moment()
          .subtract(1, "d")
          .unix();

    setOrders([]);

    queryOrders(selectedShop, { [queryKey]: queryUnix }).then(orders => {
      setOrders(
        orders.sort((a, b) => {
          if (orderSortDirection === "desc") {
            return a.ordered_at < b.ordered_at ? 1 : -1;
          } else {
            return a.ordered_at > b.ordered_at ? 1 : -1;
          }
        }),
      );
    });
  };

  const cancel = () => {
    if (!selectedShop) return;
    if (!toBeCanceledOrder) return;
    const updatedAmount = toBeCanceledOrder.paid_amount - cancelAmount;
    updateCharge(toBeCanceledOrder, updatedAmount, cancelDescription)
      .then(() => {
        setDialogMessage("キャンセル処理が完了しました");
        setCancelAmount(0);
        setCancelDescription("");
        setToBeCanceledOrder(null);
      })
      .catch(err => {
        setDialogMessage(JSON.stringify(err));
      });
  };

  const runSlackOrdersInDayPostWrap = (
    companyId: string,
    shopId: string,
    dateQuery: string,
  ) => {
    if (window.confirm("オーダーをSlackへ一括送信します，よろしいですか？")) {
      runSlackOrdersInDayPost(companyId, shopId, dateQuery);
    }
  };

  const sendSummaryWrap = () => {
    if (!selectedShop) return;
    if (
      window.confirm(
        `${selectedShop.name}の本日分の日計をSlackへ送信します。よろしいですか？`,
      )
    ) {
      sendSummary(selectedShop.company_id, selectedShop.shop_id);
    }
  };

  return (
    <>
      <ShopSelection
        selectedShop={selectedShop}
        shops={shops}
        selectShop={selectShop}
      />

      <div style={{ display: "flex" }}>
        <form className={classes.timePicker} noValidate>
          <TextField
            id="datetime-local"
            label="オーダー時刻(この時刻以降のオーダーが表示)"
            type="datetime-local"
            InputLabelProps={{ shrink: true }}
            onChange={ev => setQueryTime(ev.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setQueryKey("ordered_at");
              reloadOrder();
            }}
          >
            適用
          </Button>
        </form>

        <form className={classes.timePicker} noValidate>
          <TextField
            id="datetime-local"
            label="受取・配達希望時刻(この時刻以降のオーダーが表示)"
            type="datetime-local"
            InputLabelProps={{ shrink: true }}
            onChange={ev => setQueryTime(ev.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setQueryKey("visit_at");
              reloadOrder();
            }}
          >
            適用
          </Button>
        </form>

        <Button
          variant="contained"
          color="primary"
          startIcon={<CachedIcon />}
          style={{ margin: "30px" }}
          onClick={() => reloadOrder()}
        >
          {" "}
          リロード{" "}
        </Button>

        <div>
          <div>ソートキー</div>
          <List
            dense
            style={{
              maxHeight: "150px",
              width: "200px",
              overflow: "auto",
            }}
          >
            {["ordered_at", "visit_at", "order_number", "is_printed"].map(
              (orderKey, index) => (
                <ListItem key={index} button>
                  <ListItemText
                    id={`${orderKey}`}
                    primary={translateOrderKey(orderKey)}
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      onChange={() => setOrderSortKey(orderKey as keyof Order)}
                      checked={orderKey === orderSortKey}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ),
            )}
          </List>
        </div>

        <div>
          <div>ソートの向き</div>
          <List
            dense
            style={{
              maxHeight: "150px",
              width: "200px",
              overflow: "auto",
            }}
          >
            {["asc", "desc"].map((direction, index) => (
              <ListItem key={index} button>
                <ListItemText
                  id={`${direction}`}
                  primary={translateOrderKey(direction)}
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    onChange={() =>
                      setOrderSortDirection(direction as "asc" | "desc")
                    }
                    checked={direction === orderSortDirection}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>

        <div style={{ width: "200px" }} />

        <DangerZone>
          <Typography variant="subtitle1">さわるなキケン</Typography>
          {selectedShop && queryTime && (
            <>
              <div>
                <Button
                  onClick={() =>
                    runSlackOrdersInDayPostWrap(
                      selectedShop.company_id,
                      selectedShop.shop_id,
                      moment(queryTime)
                        .tz("Asia/Tokyo")
                        .format("YYYY-MM-DD"),
                    )
                  }
                >
                  {queryTime}日分のオーダーを一括送信する
                </Button>
              </div>
              <div style={{ marginTop: "20px" }}>
                <Button onClick={() => sendSummaryWrap()}>
                  本日分の日計送信
                </Button>
              </div>
            </>
          )}
        </DangerZone>
      </div>

      <PriceSummary>
        <div>決済総額:￥{allPaidAmount()}</div>
        <div>配送料総額:￥{allDeliveryFeeAmount()}</div>
        <div>返金総額:￥{allCancelAmount()}</div>
      </PriceSummary>

      <Paper>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "20px" }} align="left">
                Company ID
              </TableCell>
              <TableCell style={{ width: "20px" }} align="right">
                Shop ID
              </TableCell>
              <TableCell style={{ width: "20px" }} align="right">
                Shop Name
              </TableCell>
              <TableCell style={{ width: "20px" }} align="right">
                Order ID
              </TableCell>
              <TableCell style={{ width: "20px" }} align="right">
                User ID
              </TableCell>
              <TableCell align="left">注文時間</TableCell>
              <TableCell align="left">来店・配送予定時間</TableCell>
              <TableCell align="left">注文種別</TableCell>
              <TableCell align="left">オーダー状況</TableCell>
              <TableCell align="left">注文番号</TableCell>
              <TableCell align="left">既読状況</TableCell>
              <TableCell align="left">印刷状況</TableCell>
              <TableCell align="left">配送住所</TableCell>
              <TableCell align="left">決済種別</TableCell>
              <TableCell align="left">キャンセル状況</TableCell>
              <TableCell align="left">金額</TableCell>
              <TableCell align="left">キャンセル処理</TableCell>
              <TableCell align="left">詳細</TableCell>
              <TableCell align="left">Slack再送</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedOrders().map((order, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {order.company_id}
                </TableCell>
                <TableCell align="right">{order.shop_id}</TableCell>
                <TableCell align="right">{selectedShop?.name || ""}</TableCell>

                <TableCell align="right">{order.order_id}</TableCell>

                <TableCell align="right">
                  <div style={{ overflowX: "auto", width: "50px" }}>
                    {order.user_id}
                  </div>
                </TableCell>

                <TableCell align="right">
                  <div style={{ fontSize: "13px" }}>
                    {moment(order.ordered_at * 1000)
                      .tz("Asia/Tokyo")
                      .format("YYYY/MM/DD HH:mm:ss")}
                  </div>
                </TableCell>

                <TableCell align="right">
                  <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                    {moment(order.visit_at * 1000)
                      .tz("Asia/Tokyo")
                      .format("YYYY/MM/DD HH:mm:ss")}
                  </div>
                </TableCell>

                <TableCell align="right">
                  {order.order_type === OrderType.TAKEOUT ? (
                    <Chip
                      color="primary"
                      style={{ backgroundColor: "brown" }}
                      label="テイクアウト"
                    />
                  ) : (
                    <Chip
                      color="primary"
                      style={{ backgroundColor: "lightBlue" }}
                      label="デリバリー"
                    />
                  )}
                </TableCell>

                <TableCell align="right">
                  {order.order_status === OrderStatus.PREPARING && (
                    <Chip
                      color="primary"
                      style={{ backgroundColor: "red" }}
                      label="準備中"
                    />
                  )}
                  {order.order_status === OrderStatus.READY && (
                    <Chip
                      color="primary"
                      style={{ backgroundColor: "green" }}
                      label="準備完了"
                    />
                  )}
                  {order.order_status === OrderStatus.DONE && (
                    <Chip
                      color="primary"
                      style={{ backgroundColor: "lightGray" }}
                      label="提供完了"
                    />
                  )}
                </TableCell>

                <TableCell align="right">{order.order_number}</TableCell>

                <TableCell align="right">
                  {order.is_read ? (
                    <Chip
                      color="primary"
                      style={{ backgroundColor: "green" }}
                      label="既読"
                    />
                  ) : (
                    <Chip
                      color="primary"
                      style={{ backgroundColor: "orange" }}
                      label="未読"
                    />
                  )}
                </TableCell>

                <TableCell align="right">
                  {order.is_printed ? (
                    <Chip
                      color="primary"
                      style={{ backgroundColor: "green" }}
                      label="印刷済み"
                    />
                  ) : (
                    <Chip
                      color="primary"
                      style={{ backgroundColor: "orange" }}
                      label="未印刷"
                    />
                  )}
                </TableCell>

                <TableCell style={{ width: "150px" }} align="left">
                  <div style={{ width: "300px" }}>
                    {order.address_info && (
                      <>
                        <div style={{ width: "100%" }}>
                          <p className={classes.addressLabel}>〒:</p>
                          {order.address_info.zipCode}
                        </div>
                        <div style={{ width: "100%" }}>
                          <p className={classes.addressLabel}>住所:</p>
                          {order.address_info.address1}
                        </div>
                        <div style={{ width: "100%" }}>
                          <p className={classes.addressLabel}>番地:</p>
                          {order.address_info.address2}
                        </div>
                      </>
                    )}
                    <div style={{ width: "100%" }}>
                      <p className={classes.addressLabel}>TEL:</p>
                      {order.phone_number}
                    </div>
                    {order.nick_name && (
                      <div style={{ width: "100%" }}>
                        <p className={classes.addressLabel}>
                          受取者名:{order.nick_name}
                        </p>
                        {order.phone_number}
                      </div>
                    )}
                  </div>
                </TableCell>

                <TableCell align="right">
                  {order.payment_method === PaymentMethod.CARD ? (
                    <Chip
                      color="primary"
                      style={{ backgroundColor: "#5d5ddf" }}
                      label="Stripe決済"
                    />
                  ) : (
                    <Chip
                      color="primary"
                      style={{ backgroundColor: "lightGray" }}
                      label="現地決済"
                    />
                  )}
                </TableCell>

                <TableCell align="left">
                  <div
                    style={{
                      width: "150px",
                      backgroundColor: "red",
                      color: "white",
                    }}
                  >
                    {order.is_canceled && "全額キャンセル済み"}
                  </div>
                  <div>
                    {order.cancel_description && (
                      <div>
                        <p>キャンセル理由: {order.cancel_description}</p>
                        <p>返金額: {order.refunded_amount}</p>
                      </div>
                    )}
                  </div>
                </TableCell>

                <TableCell align="right">{`￥${order.paid_amount}`}</TableCell>

                <TableCell align="right">
                  <Button onClick={() => setToBeCanceledOrder(order)}>
                    キャンセル処理をする
                  </Button>
                </TableCell>

                <TableCell align="right">
                  <Button
                    color="primary"
                    onClick={() => setSelectedOrder(order)}
                  >
                    詳細確認
                  </Button>
                </TableCell>

                <TableCell align="right">
                  <Button
                    color="primary"
                    onClick={() => runSlackOrderPost(order)}
                  >
                    Slack再送
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>

      <Dialog
        open={toBeCanceledOrder !== null}
        onClose={() => {
          setCancelAmount(0);
          setCancelDescription("");
          setToBeCanceledOrder(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">キャンセル処理</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              オーダー情報
              <p>orderId: {toBeCanceledOrder?.order_id}</p>
              <p>注文番号: {toBeCanceledOrder?.order_number}</p>
              <p>決済額: ￥{toBeCanceledOrder?.paid_amount}</p>
            </div>

            <TextField
              required
              id="cancel-amount-input"
              label="返金額"
              value={cancelAmount}
              variant="filled"
              type="number"
              onChange={ev => setCancelAmount(Number(ev.target.value))}
            />

            <TextField
              required
              id="cancel-description-input"
              label="キャンセル・返金理由"
              variant="filled"
              value={cancelDescription}
              type="string"
              onChange={ev => setCancelDescription(ev.target.value)}
            />

            <Button onClick={() => cancel()}>キャンセル実行</Button>
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>

      {selectedOrder && (
        <OrderDetailDialog
          order={selectedOrder}
          isOpen={!!selectedOrder}
          onClose={() => setSelectedOrder(null)}
        />
      )}
    </>
  );
};

export default Orders;

const DangerZone = styled.div`
  border: 1px solid red;
  border-radius: 5px;
  padding: 10px;
`;

const PriceSummary = styled.div`
  font-size: 25px;
  font-weight: bold;
`;

import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@material-ui/core";
import { Option } from "../../../entity/option.entity";
import { Choice } from "../../../entity/choice.entity";
import { OptionComponent } from "../menus/OptionDialog/components/OptionComponent";
import * as request from "../../../plugins/requests";
import CreateOptionDialog from "./CreateOptionDialog";
import { ShopSelection } from "../../components/shopSelection";
import { useShops } from "../../../hooks/useShops";

const Options: React.FC = () => {
  const { shops, companies, selectedShop, setSelectedShop } = useShops();
  const [options, setOptions] = useState([] as Option[]);
  const [choices, setChoices] = useState([] as Choice[]);

  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const [createOptionModalOpen, setCreateOptionModalOpen] = useState(false);
  const [openChoiceIdsEditor, setOpenChoiceIdsEditor] = useState(
    {} as { [optionId: string]: boolean },
  );

  const updateOption = async (
    option: Option,
    updateValue: Partial<Option>,
  ): Promise<void> => {
    try {
      const toBeUpdatedOption: Option = { ...option, ...updateValue };

      // Note: isMultiple true だと，セレクトボタンになる
      //  isRequired true だと，ラジオボタンになる
      //  maxChoiceNum = 2 => isMultiple = true
      //  minChoiceNum = 0 => isMultiple = true, isRequired = false
      //  maxCHoiceNum = minChoiceNum => isRequired = true
      const isRequired = toBeUpdatedOption.min_choice_num > 0;
      const isMultiple =
        toBeUpdatedOption.max_choice_num > 1 ||
        toBeUpdatedOption.min_choice_num === 0;
      console.log(
        "also update flags:",
        isRequired,
        isMultiple,
        toBeUpdatedOption,
      );

      const updatedOption = await request.updateOption(
        option.company_id,
        option.shop_id,
        option.option_id,
        {
          ...updateValue,
          is_required: isRequired,
          is_multiple: isMultiple,
        },
      );

      setOptions(
        options.map(existingOption =>
          existingOption.option_id === updatedOption.option_id
            ? updatedOption
            : existingOption,
        ),
      );

      setDialogMessage("update success");
    } catch (err) {
      setDialogMessage(JSON.stringify(err, null, 2));
    }
  };

  const selectShop = (shopId: string) => {
    const shop = shops.find(_shop => _shop.shop_id === shopId);
    if (!shop) return;
    const company = companies.find(
      _company => _company.company_id === shop.company_id,
    );
    if (!company) return;
    setSelectedShop(shop);
  };

  useEffect(() => {
    if (!selectedShop) return;
    request
      .getOptions(selectedShop.company_id, selectedShop.shop_id)
      .then(options => {
        setOptions([...options]);
        options.forEach(option => {
          setOpenChoiceIdsEditor(prev => ({
            ...prev,
            [option.option_id]: false,
          }));
        });
      });
    request
      .getChoices(selectedShop.company_id, selectedShop.shop_id)
      .then(choices => {
        setChoices([...choices]);
      });
  }, [selectedShop]);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        style={{ width: "70vw", marginBottom: "30px" }}
        onClick={() => setCreateOptionModalOpen(true)}
      >
        オプションを作成する
      </Button>

      <ShopSelection
        selectedShop={selectedShop}
        shops={shops}
        selectShop={selectShop}
      />

      <Paper>
        {options.map(option => {
          return (
            <OptionComponent
              option={option}
              updateOption={updateValue => updateOption(option, updateValue)}
              choices={choices}
              openChoiceIdsEditor={openChoiceIdsEditor[option.option_id]}
              setOpenChoiceIdsEditor={(val: boolean) => {
                setOpenChoiceIdsEditor(prev => ({
                  ...prev,
                  [option.option_id]: val,
                }));
              }}
            />
          );
        })}
      </Paper>

      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>

      {selectedShop && (
        <CreateOptionDialog
          shop={selectedShop}
          isOpen={createOptionModalOpen}
          onClose={() => setCreateOptionModalOpen(false)}
        />
      )}
    </>
  );
};

export default Options;

import React, { useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  createStyles,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
  Theme,
  Button,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { createMenu, CreateMenuRequest } from "../../../plugins/requests";
import { Shop } from "../../../entity/shop.entity";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: 800,
      },
    },
  }),
);
// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface Props {
  shop: Shop;
  isOpen: boolean;
  onClose: () => void;
}

const CreateMenuDialog: React.FC<Props> = ({
  shop,
  isOpen,
  onClose,
}: Props) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [shopDisplayName, setShopDisplayName] = useState("");
  const [price, setPrice] = useState(0);
  const [priority, setPriority] = useState(0);
  const [categoryId, setCategoryId] = useState(null as string | null);
  const [menuTypeId, setMenuTypeId] = useState(null as string | null);
  const [imageUrl, setImageUrl] = useState("");
  const [description, setDescription] = useState(null as string | null);
  const [maxNum, setMaxNum] = useState(-1);

  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const create = () => {
    if (!categoryId || !menuTypeId) return;
    const createMenuRequest: CreateMenuRequest = {
      category_id: categoryId,
      menu_type_id: menuTypeId,
      name,
      price,
      priority,
      image_url: imageUrl === "" ? undefined : imageUrl,
      option_ids: [],
      description: description && description !== "" ? description : undefined,
      max_num: maxNum,
      shop_display_name: shopDisplayName === "" ? undefined : shopDisplayName,
    };
    createMenu(shop, createMenuRequest)
      .then(() => {
        setDialogMessage("update success");
      })
      .catch(err => {
        setDialogMessage(JSON.stringify(err));
      });
  };

  return (
    <>
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle id="scroll-dialog-title">メニュー作成</DialogTitle>
        <DialogContent>
          <form className={classes.root} autoComplete="off">
            <div>
              <TextField
                required
                id="name-input"
                label="メニュー名"
                value={name}
                variant="filled"
                type="string"
                onChange={ev => setName(ev.target.value)}
              />

              <TextField
                required
                id="shop-display-name-input"
                label="店舗表示名"
                value={shopDisplayName}
                variant="filled"
                type="string"
                onChange={ev => setShopDisplayName(ev.target.value)}
              />

              <TextField
                required
                id="description-id-input"
                label="Description"
                value={description}
                variant="filled"
                type="string"
                onChange={ev => setDescription(ev.target.value)}
              />

              <TextField
                required
                id="price-input"
                label="値段"
                variant="filled"
                value={price}
                type="number"
                onChange={ev => setPrice(Number(ev.target.value))}
              />

              <TextField
                required
                id="max-num-input"
                label="MaxNum"
                variant="filled"
                value={maxNum}
                type="number"
                onChange={ev => setMaxNum(Number(ev.target.value))}
              />

              <TextField
                required
                id="priority-input"
                label="Priority"
                variant="filled"
                value={priority}
                type="number"
                onChange={ev => setPriority(Number(ev.target.value))}
              />
            </div>

            <div>カテゴリ</div>
            <List
              dense
              style={{
                maxHeight: "100px",
                width: "300px",
                overflow: "auto",
              }}
            >
              {shop.categories.map((category, categoryIndex) => (
                <ListItem key={categoryIndex} button>
                  <ListItemText
                    id={`${category.category_id}`}
                    primary={category.name}
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      onChange={() => setCategoryId(category.category_id)}
                      checked={categoryId === category.category_id}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>

            <div>メニュータイプ</div>
            <List
              dense
              style={{
                maxHeight: "100px",
                width: "300px",
                overflow: "auto",
              }}
            >
              {shop.menu_types.map((menuType, menuTypeIndex) => (
                <ListItem key={menuTypeIndex} button>
                  <ListItemText
                    id={`${menuType.menu_type_id}`}
                    primary={menuType.name}
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      onChange={() => setMenuTypeId(menuType.menu_type_id)}
                      checked={menuTypeId === menuType.menu_type_id}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>

            <div>
              <TextField
                id="image-url-input"
                value={imageUrl}
                label="画像URL"
                variant="filled"
                type="string"
                onChange={ev => setImageUrl(ev.target.value)}
              />
            </div>

            <div>
              <Button type="button" onClick={create}>
                {" "}
                作成{" "}
              </Button>
              <Button onClick={onClose}> 閉じる </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default CreateMenuDialog;

import axiosBase from "axios";
import * as firebase from "firebase/app";
import "firebase/auth";

const getBaseURL = () => {
  if (process.env.REACT_APP_USE_LOCAL_API === "yes") {
    return "http://localhost:3000";
  }

  switch (process.env.REACT_APP_ENV) {
    case "development":
    case "staging":
      return "https://api.stag.for.dinii.jp/v2";
    case "production":
      return "https://api.for.dinii.jp/v2";
    default:
      throw new Error("invalid env");
  }
};

const request = axiosBase.create({
  baseURL: getBaseURL(),
});

request.interceptors.request.use(async config => {
  const user = firebase.auth().currentUser;
  if (!user) throw new Error("user not login");
  // eslint-disable-next-line no-param-reassign
  config.headers["x-auth-key"] = await user.getIdToken();
  return config;
});

request.interceptors.response.use(async response => {
  return response.data;
});

export default request;

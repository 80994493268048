import React from "react";
import {
  Checkbox,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Menu } from "../../../../../entity/menu.entity";
import { Option } from "../../../../../entity/option.entity";

export interface OptionIdEditorProps {
  isOpen: boolean;
  menu: Menu;
  options: Option[];
  className: string;
  updateValue: (optionId: string, value: boolean) => void;
  onClose: () => void;
}

export const OptionIdEditorDialog: React.FC<OptionIdEditorProps> = ({
  menu,
  isOpen,
  options,
  onClose,
  updateValue,
  className,
}: OptionIdEditorProps) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogContent>
      <List dense className={className}>
        {options.map((option, index) => (
          <ListItem key={index} button>
            <ListItemText
              id={`${option.option_id}`}
              primary={option.option_id + "/" + option.name}
            />
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                onChange={ev => {
                  updateValue(option.option_id, ev.target.checked);
                }}
                checked={menu.option_ids?.includes(option.option_id)}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </DialogContent>
  </Dialog>
);

import React, { useState, useCallback } from "react";
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // eslint-disable-next-line no-unused-vars
  Theme,
  Typography,
  TextField,
} from "@material-ui/core";
import DataTableRowComponent from "../../components/dataTableRowComponent";
import CreateShopDialog from "./CreateShopDialog";
import { Shop, SummaryType } from "../../../entity/shop.entity";
import {
  updateShop,
  ShopImageKeys,
  createShopImageUrl,
  uploadShopImage,
} from "../../../plugins/requests";
import moment from "moment-timezone";
import { useShops } from "../../../hooks/useShops";
import { getShops } from "../../../plugins/requests";

const createObjectURL = (window.URL || window.webkitURL).createObjectURL;

const addRandUnix = (val: string): string => `${val}?${moment().unix()}`;

const parseZipCodes = (rawZipCodes: string): string[] => {
  return rawZipCodes.split(",").map(z => z.trim());
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    form: {
      "& > *": {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
  }),
);

const Shops: React.FC = () => {
  const classes = useStyles();

  const {
    companies,
    shops,
    setShops,
    selectedShop,
    setSelectedShop,
  } = useShops();

  const [filterShopName, setFilterShopName] = useState("");
  const [filterCompanyIds, setFilterCompanyIds] = useState([] as string[]);
  const [filterShopId, setFilterShopId] = useState<string>("");

  const filterdShops = useCallback(() => {
    return shops
      .filter(shop =>
        filterCompanyIds.length === 0
          ? false
          : filterCompanyIds.includes(shop.company_id),
      )
      .filter(
        shop =>
          filterShopName === "" || shop.name.indexOf(filterShopName) !== -1,
      )
      .filter(
        shop =>
          filterShopId === "" || shop.shop_id.indexOf(filterShopId) !== -1,
      );
  }, [filterCompanyIds, filterShopId, filterShopName, shops]);

  // edit flags
  const [editFocusIndex, setEditFocusIndex] = useState(null as null | number);
  const [uploadMessage, setUploadMessage] = useState(null as null | string);

  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const [createShopModalOpen, setCreateShopModalOpen] = useState(false);
  const [openUploaderDialog, setOpenUploaderDialog] = useState(false);
  const [openZipCodeDialog, setOpenZipCodeDialog] = useState(
    null as number | null,
  );

  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [selectedImageKey, setSelectedImageKey] = useState(ShopImageKeys.TOP);
  let fileUploaded: HTMLInputElement | null;

  const update = <K extends keyof Shop>(shop: Shop, key: K, value: Shop[K]) => {
    updateShop(shop.company_id, shop.shop_id, { [key]: value })
      .then(updatedShop => {
        setShops(
          shops.map(existingShop =>
            existingShop.shop_id === updatedShop.shop_id
              ? updatedShop
              : existingShop,
          ),
        );
        setDialogMessage("update success");
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
    setEditFocusIndex(null);
  };

  const handleChangeFile = (e: any) => {
    const files = e.target.files;
    const selectedImageUrl =
      files.length === 0 ? "" : createObjectURL(files[0]);
    setSelectedImageUrl(selectedImageUrl);
  };

  const uploadImage = async (shop: Shop, imageKey: ShopImageKeys) => {
    const url = await createShopImageUrl(
      shop.company_id,
      shop.shop_id,
      imageKey,
    );

    try {
      const fileData = fileUploaded!.files![0];
      await uploadShopImage(fileData, `${imageKey}.jpg`, url);

      const imageUrl = url.split("?")[0];
      console.log("imageUrl chekc:", imageUrl);
      await updateShop(shop.company_id, shop.shop_id, {
        [imageKey]: addRandUnix(imageUrl),
      });
      setUploadMessage("アップロードが完了しました");
    } catch (err) {
      console.error(err);
      setUploadMessage("アップロードに失敗しました");
      throw err;
    } finally {
      companies.forEach(company => {
        getShops(company.company_id).then(shops_ => {
          setShops([...shops, ...shops]);
        });
      });
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        style={{ width: "70vw", marginBottom: "30px" }}
        onClick={() => setCreateShopModalOpen(true)}
      >
        店舗を作成する
      </Button>

      <div style={{ display: "flex" }}>
        <div>
          <Typography variant="h5">CompanyIdでフィルター</Typography>
          <List
            dense
            style={{ width: "300px", overflow: "auto", maxHeight: 300 }}
          >
            {companies.map(({ company_id }, index) => (
              <ListItem key={index} button>
                <ListItemText id={`${index}`} primary={company_id} />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    onChange={() => {
                      if (filterCompanyIds.includes(company_id)) {
                        setFilterCompanyIds(prev =>
                          prev.filter(v => v !== company_id),
                        );
                      } else {
                        setFilterCompanyIds(prev => [...prev, company_id]);
                      }
                    }}
                    checked={filterCompanyIds.includes(company_id)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>

        <div>
          <Typography variant="h5">店舗名でフィルター</Typography>
          <TextField
            required
            id="name-filter-input"
            label="店舗名"
            value={filterShopName}
            variant="filled"
            type="string"
            onChange={ev => setFilterShopName(ev.target.value)}
          />
        </div>

        <div>
          <Typography variant="h5">店舗IDでフィルター</Typography>
          <TextField
            required
            id="shop-id-filter-input"
            label="店舗ID"
            value={filterShopId}
            variant="filled"
            type="string"
            onChange={ev => setFilterShopId(ev.target.value)}
          />
        </div>
      </div>

      <Paper>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Company ID</TableCell>
              <TableCell align="left">Shop ID</TableCell>
              <TableCell align="left">Shop Name</TableCell>
              <TableCell align="left">受け取り方法の表示</TableCell>
              <TableCell align="left">オーダー時ニックネーム要求</TableCell>
              <TableCell align="left">Owner ID</TableCell>
              <TableCell align="left">Slack送信先</TableCell>
              <TableCell align="left">開店閉店設定</TableCell>
              <TableCell align="left">閉店時の表示メッセージ</TableCell>
              <TableCell align="left">表示非表示設定</TableCell>
              <TableCell align="left">Ex tax(ONで外税)</TableCell>
              <TableCell align="left">税率</TableCell>
              <TableCell align="left">MaxNum</TableCell>
              <TableCell align="left">
                印刷時間(来店・配送の設定時間前になったら印刷．
                当日分しか印刷対象ではないため，24時間(1440)設定にすると即時印刷になる)
              </TableCell>
              <TableCell align="left">
                最短受付時間(テイクアウト)/分単位
              </TableCell>
              <TableCell align="left">最短配送時間/分単位</TableCell>
              <TableCell align="left">
                テイクアウト受付日数(2にすると今日明日が選択可能)
              </TableCell>
              <TableCell align="left">
                デリバリー受付日数(2にすると今日明日が選択可能)
              </TableCell>
              <TableCell align="left">
                テイクアウトスロット単位(分単位)
              </TableCell>
              <TableCell align="left">デリバリー単位(分単位)</TableCell>
              <TableCell align="left">Open</TableCell>
              <TableCell align="left">Close</TableCell>
              <TableCell align="left">配送手数料</TableCell>
              <TableCell align="left">テイクアウト</TableCell>
              <TableCell align="left">デリバリー</TableCell>
              <TableCell align="left">Stripe決済</TableCell>
              <TableCell align="left">現地決済</TableCell>
              <TableCell align="left">日計の集計方法</TableCell>
              <TableCell align="left">
                備考の無効化(ONで備考が無くなります)
              </TableCell>
              <TableCell align="left">店舗画像</TableCell>
              <TableCell align="left">地図URL</TableCell>
              <TableCell align="left">画像類のアップロード</TableCell>
              <TableCell align="left">表示順(低いほど上)</TableCell>
              <TableCell align="left">
                配送可能な郵便番号一覧(ハイフンなし)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterdShops().map((shop, index) => (
              <TableRow key={index}>
                <TableCell component="th" style={{ width: "20px" }} scope="row">
                  {shop.company_id}
                </TableCell>

                <TableCell align="right" style={{ width: "20px" }}>
                  {shop.shop_id}
                </TableCell>

                <TableCell align="right" style={{ width: "20px" }}>
                  {DataTableRowComponent({
                    defaultValue: shop.name,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "name", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>

                <TableCell align="right" style={{ width: "20px" }}>
                  <Switch
                    required
                    checked={shop.is_show_how_to_get_item}
                    onChange={() =>
                      update(
                        shop,
                        "is_show_how_to_get_item",
                        !shop.is_show_how_to_get_item,
                      )
                    }
                  />
                </TableCell>

                <TableCell align="right" style={{ width: "20px" }}>
                  <Switch
                    required
                    checked={shop.require_nick_name}
                    onChange={() =>
                      update(shop, "require_nick_name", !shop.require_nick_name)
                    }
                  />
                </TableCell>

                <TableCell align="right" style={{ width: "20px" }}>
                  {DataTableRowComponent({
                    defaultValue: shop.owner_id,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "owner_id", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>

                <TableCell align="right" style={{ width: "20px" }}>
                  {DataTableRowComponent({
                    defaultValue: shop.slack_post_channel,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "slack_post_channel", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>

                <TableCell align="right" style={{ width: "20px" }}>
                  <Switch
                    required
                    checked={shop.is_open}
                    onChange={() => update(shop, "is_open", !shop.is_open)}
                  />
                </TableCell>
                <TableCell align="right" style={{ width: "20px" }}>
                  {DataTableRowComponent({
                    defaultValue: shop.close_text,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "close_text", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>

                <TableCell align="right">
                  <Switch
                    required
                    checked={shop.is_display}
                    onChange={() =>
                      update(shop, "is_display", !shop.is_display)
                    }
                  />
                </TableCell>

                <TableCell align="right">
                  <Switch
                    required
                    checked={shop.ex_tax}
                    onChange={() => update(shop, "ex_tax", !shop.ex_tax)}
                  />
                </TableCell>

                <TableCell align="right" style={{ width: "20px" }}>
                  {DataTableRowComponent({
                    defaultValue: shop.tax_rate,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "tax_rate", Number(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "number",
                  })}
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: shop.max_num,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "max_num", Number(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "number",
                  })}
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: shop.print_ready_time,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "print_ready_time", Number(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "number",
                  })}
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: shop.ready_time,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "ready_time", Number(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "number",
                  })}
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: shop.delivery_ready_time,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "delivery_ready_time", Number(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "number",
                  })}
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: shop.takeout_visit_date_length,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "takeout_visit_date_length", Number(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "number",
                  })}
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: shop.delivery_visit_date_length,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "delivery_visit_date_length", Number(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "number",
                  })}
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: shop.takeout_slot_step,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "takeout_slot_step", Number(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "number",
                  })}
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: shop.delivery_slot_step,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "delivery_slot_step", Number(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "number",
                  })}
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: shop.open,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "open", String(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                  })}
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: shop.close,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "close", String(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                  })}
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: shop.delivery_fee,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "delivery_fee", Number(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                  })}
                </TableCell>

                <TableCell align="right">
                  <Switch
                    required
                    checked={shop.enable_takeout}
                    onChange={() =>
                      update(shop, "enable_takeout", !shop.enable_takeout)
                    }
                  />
                </TableCell>

                <TableCell align="right">
                  <Switch
                    required
                    checked={shop.enable_delivery}
                    onChange={() =>
                      update(shop, "enable_delivery", !shop.enable_delivery)
                    }
                  />
                </TableCell>

                <TableCell align="right">
                  <Switch
                    required
                    checked={shop.enable_stripe}
                    onChange={() =>
                      update(shop, "enable_stripe", !shop.enable_stripe)
                    }
                  />
                </TableCell>

                <TableCell align="right">
                  <Switch
                    required
                    checked={shop.enable_on_site_payment}
                    onChange={() =>
                      update(
                        shop,
                        "enable_on_site_payment",
                        !shop.enable_on_site_payment,
                      )
                    }
                  />
                </TableCell>

                <TableCell align="right">
                  <List dense>
                    <ListItem button>
                      <ListItemText
                        id={`${index}_order_summary_per_menu`}
                        primary={"メニューごと"}
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          onChange={() =>
                            update(
                              shop,
                              "order_summary_type",
                              SummaryType.PER_MENU,
                            )
                          }
                          checked={
                            shop.order_summary_type === SummaryType.PER_MENU
                          }
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem button>
                      <ListItemText
                        id={`${index}_order_summary_per_order`}
                        primary={"注文ごと"}
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          onChange={() =>
                            update(
                              shop,
                              "order_summary_type",
                              SummaryType.PER_ORDER,
                            )
                          }
                          checked={
                            shop.order_summary_type === SummaryType.PER_ORDER
                          }
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </TableCell>

                <TableCell align="right">
                  <Switch
                    required
                    checked={shop.disable_remark}
                    onChange={() =>
                      update(shop, "disable_remark", !shop.disable_remark)
                    }
                  />
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: shop.top_image_url,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "top_image_url", String(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                  })}
                  {shop.top_image_url && shop.top_image_url !== "" && (
                    <div>
                      <img
                        src={shop.top_image_url}
                        width={50}
                        height={50}
                        alt=""
                      />
                    </div>
                  )}
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: shop.map_image_url,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "map_image_url", String(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                  })}
                </TableCell>

                <TableCell align="right">
                  <Button
                    color="primary"
                    onClick={() => {
                      setSelectedShop(shop);
                      setOpenUploaderDialog(true);
                      setSelectedImageKey(ShopImageKeys.TOP);
                    }}
                  >
                    店舗アップロード
                  </Button>
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: shop.priority,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      update(shop, "priority", Number(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                  })}
                </TableCell>

                <TableCell align="right">
                  <Button onClick={() => setOpenZipCodeDialog(index)}>
                    配達可能範囲設定
                  </Button>
                  <Dialog
                    open={openZipCodeDialog === index}
                    onClose={() => setOpenZipCodeDialog(null)}
                    aria-labelledby="zip-code-dialog-title"
                    aria-describedby="zip-code-dialog-description"
                  >
                    <DialogTitle id="zip-code-dialog-title">
                      配達可能郵便番号設定
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="zip-code-dialog-description">
                        {DataTableRowComponent({
                          defaultValue:
                            shop.delivery_scope_zip_codes &&
                            shop.delivery_scope_zip_codes.join(),
                          startEdit: () => setEditFocusIndex(index),
                          cancelEdit: () => setEditFocusIndex(null),
                          applyChange: (val: any) => {
                            const zipCodes = parseZipCodes(val);
                            update(shop, "delivery_scope_zip_codes", zipCodes);
                          },
                          formClassName: classes.form,
                          isShowEditForm: editFocusIndex === index,
                        })}
                      </DialogContentText>
                    </DialogContent>
                  </Dialog>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>

      {openUploaderDialog && selectedShop && (
        <Dialog
          open={openUploaderDialog}
          onClose={() => {
            setOpenUploaderDialog(false);
            setUploadMessage(null);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">画像アップローダー</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {selectedImageKey === ShopImageKeys.TOP ? (
                <h2>店舗画像</h2>
              ) : (
                <h2>地図画像</h2>
              )}
              <input
                ref={ref => (fileUploaded = ref)}
                type="file"
                id="shop-image"
                onChange={e => handleChangeFile(e)}
              />
              <img src={selectedImageUrl} width={200} height={200} alt="" />
              <button
                onClick={() => uploadImage(selectedShop, selectedImageKey)}
              >
                画像のアップロード
              </button>
              <div style={{ fontSize: "20px", color: "#f47a55" }}>
                {uploadMessage || ""}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions />
        </Dialog>
      )}

      <CreateShopDialog
        companies={companies}
        isOpen={createShopModalOpen}
        onClose={() => setCreateShopModalOpen(false)}
      />
    </>
  );
};

export default Shops;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import firebase from "../../../plugins/firebase";
import { green, red } from "@material-ui/core/colors";
import { TransitionProps } from "@material-ui/core/transitions";
const Moment = require("moment");

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  okCell: {
    backgroundColor: green.A100,
  },
  ngCell: {
    backgroundColor: red.A100,
  },
});

enum LogType {
  startOperation = "startOperation",
  waitingHome = "waitingHome",
  reboot = "reboot",
}

interface DeviceMonitoringLog {
  companyId: string;
  shopId: string;
  shopClientId: string;
  isCharging: boolean;
  battery: number;
  loggedAt: any;
  type: LogType;
}

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

interface Props {
  isOpen: boolean;
  companyId?: string;
  shopId?: string;
  shopClientId?: string;
  onClose: () => void;
}

const DeviceLogsDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const [logs, setLogs] = useState([] as DeviceMonitoringLog[]);

  useEffect(() => {
    const unsubscribe =
      props.companyId && props.shopId && props.shopClientId
        ? firebase
            .firestore()
            .collection("device-monitoring")
            .where("companyId", "==", props.companyId)
            .where("shopId", "==", props.shopId)
            .where("shopClientId", "==", props.shopClientId)
            .orderBy("loggedAt", "desc")
            .onSnapshot(
              snapshot => {
                console.log("get query");
                const logs = snapshot.docs.map(value => {
                  return value.data();
                }) as DeviceMonitoringLog[];
                setLogs(logs);
              },
              err => {
                console.log(`Encountered error: ${err}`);
              },
            )
        : () => {};
    return unsubscribe;
  }, [props.companyId, props.shopId, props.shopClientId]);
  return (
    <>
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={props.isOpen}
        onClose={props.onClose}
        onClick={props.onClose}
      >
        <DialogTitle id="scroll-dialog-title">
          {`${props.companyId}-${props.shopId}-${props.shopClientId}`}
        </DialogTitle>
        <DialogContent>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">logged at</TableCell>
                <TableCell align="left">type</TableCell>
                <TableCell align="left">battery</TableCell>
                <TableCell align="left">charging</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log, index) => (
                <TableRow key={index}>
                  <TableCell align="left">
                    {formatDate(log.loggedAt.toDate())}
                  </TableCell>
                  <TableCell align="left">{log.type}</TableCell>
                  <TableCell align="left">{log.battery}</TableCell>
                  <TableCell align="left">{log.isCharging}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
};

const formatDate = (date: Date) => {
  return Moment(date).format("YYYY-MM-DD HH:mm:ss");
};

export default DeviceLogsDialog;

import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  createStyles,
  makeStyles,
  Theme,
  Button,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { Shop } from "../../../entity/shop.entity";
import { ShopClientRole } from "../../../entity/shopClient.entity";
import {
  createShopClient,
  CreateShopClientRequest,
} from "../../../plugins/requests";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: 800,
      },
    },
  }),
);
// eslint-disable-next-line react/display-name
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

interface Props {
  shop: Shop;
  isOpen: boolean;
  onClose: () => void;
}

const CreateShopClientDialog: React.FC<Props> = ({
  shop,
  isOpen,
  onClose,
}: Props) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [roles, setRoles] = useState([] as ShopClientRole[]);

  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const create = () => {
    const createShopClientRequest: CreateShopClientRequest = {
      email,
      password,
      name,
      roles,
    };
    createShopClient(shop.company_id, shop.shop_id, createShopClientRequest)
      .then(() => {
        setDialogMessage("create success");
      })
      .catch(err => {
        setDialogMessage(JSON.stringify(err));
      });
  };

  return (
    <>
      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle id="scroll-dialog-title">店舗アカウント作成</DialogTitle>
        <DialogContent>
          <form className={classes.root} autoComplete="off">
            <div>
              <TextField
                required
                id="name-input"
                label="名前"
                value={name}
                variant="filled"
                type="string"
                onChange={ev => setName(ev.target.value)}
              />

              <TextField
                required
                id="email-input"
                label="email"
                variant="filled"
                value={email}
                type="string"
                onChange={ev => setEmail(ev.target.value)}
              />

              <TextField
                required
                id="password-input"
                label="password"
                variant="filled"
                value={password}
                type="string"
                onChange={ev => setPassword(ev.target.value)}
              />

              <div>
                <List dense>
                  {Object.values(ShopClientRole).map(
                    (shopClientRole, index) => {
                      return (
                        <ListItem key={index} button>
                          <ListItemText
                            id={shopClientRole}
                            primary={shopClientRole}
                          />
                          <ListItemSecondaryAction>
                            <Checkbox
                              edge="end"
                              onChange={() => {
                                if (roles.includes(shopClientRole)) {
                                  setRoles(prev =>
                                    prev.filter(s => s !== shopClientRole),
                                  );
                                } else {
                                  setRoles(prev => [...prev, shopClientRole]);
                                }
                              }}
                              checked={roles.includes(shopClientRole)}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    },
                  )}
                </List>
              </div>
            </div>

            <div>
              <Button type="button" onClick={create}>
                {" "}
                作成{" "}
              </Button>
              <Button onClick={onClose}> 閉じる </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default CreateShopClientDialog;

import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ShopSelection } from "../../components/shopSelection";
import { useShops } from "../../../hooks/useShops";
import moment from "moment-timezone";
import styled from "styled-components";
import {
  dumpSummaryRequest,
  downloadCsv,
  listCsv,
} from "../../../plugins/paymentManagementApi";
import AutorenewIcon from "@material-ui/icons/Autorenew";

const PaymentManagement: React.FC = () => {
  const { shops, companies, selectedShop, setSelectedShop } = useShops();

  const [startDate, setStartDate] = useState(null as Date | null);
  const [endDate, setEndDate] = useState(null as Date | null);
  const [payoutId, setPayoutId] = useState(null as string | null);
  const [paymentMethod, setPaymentMethod] = useState(null as string | null);
  const [files, setFiles] = useState(
    [] as { Key: string; LastModified: string }[],
  );
  const [isLoading, setIsLoading] = useState(false);

  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const selectShop = (shopId: string) => {
    const shop = shops.find(_shop => _shop.shop_id === shopId);
    if (!shop) return;
    const company = companies.find(
      _company => _company.company_id === shop.company_id,
    );
    if (!company) return;
    setSelectedShop(shop);
  };

  const dumpSummary = async () => {
    console.log(selectedShop, paymentMethod, startDate, endDate);
    if (!selectedShop) return;
    if (!paymentMethod) return;
    if (!startDate) return;
    if (!endDate) return;

    setIsLoading(true);

    try {
      await dumpSummaryRequest({
        companyId: selectedShop.company_id,
        shopId: selectedShop.shop_id,
        payoutId: payoutId ? payoutId : undefined,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        paymentMethod,
      });
    } catch (err) {
      setDialogMessage(`エラー: JSON.stringify(err)`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ShopSelection
        selectedShop={selectedShop}
        shops={shops}
        selectShop={selectShop}
      />

      <div style={{ display: "flex", alignItems: "center" }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div style={{ margin: "20px" }}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy/MM/dd"
              margin="normal"
              id="date-picker-inline"
              label="集計開始日"
              value={startDate}
              onChange={ev => setStartDate(ev)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </div>
          <div style={{ margin: "20px" }}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy/MM/dd"
              margin="normal"
              id="date-picker-inline"
              label="集計締日"
              value={endDate}
              onChange={ev => setEndDate(ev)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </div>
        </MuiPickersUtilsProvider>

        <div style={{ margin: "20px" }}>
          <div>PayoutIdの指定</div>
          <TextField onChange={ev => setPayoutId(ev.target.value)} />
        </div>

        <div style={{ margin: "20px" }}>
          <div>決済種別の指定</div>
          <List>
            {["card", "cash"].map((value, index) => (
              <div key={index}>
                <ListItem button key={index}>
                  <ListItemText primary={value} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      onChange={() => {
                        setPaymentMethod(value);
                      }}
                      checked={paymentMethod === value}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </div>
            ))}
          </List>
        </div>
      </div>

      <div style={{ width: "40%", fontSize: "20px", margin: "30px 0" }}>
        <Button
          variant="contained"
          color="primary"
          style={{ width: "100%" }}
          onClick={() => dumpSummary()}
        >
          明細出力する
          {isLoading && <CircularProgress />}
        </Button>
      </div>

      <div
        style={{
          border: "1px solid black",
          borderRadius: "10px",
          width: "80%",
          minHeight: "100px",
          padding: "20px",
        }}
      >
        <div style={{ borderBottom: "1px solid lightGray" }}>
          <Button
            color="primary"
            onClick={() => {
              listCsv().then(ret => setFiles(ret.Contents));
            }}
          >
            取得可能なCSVリストの更新
            <AutorenewIcon />
          </Button>
        </div>
        {files.map(fileData => (
          <AvailableFiles>
            <div style={{ width: "60%" }}> {fileData.Key} </div>
            <div style={{ width: "20%" }}>
              更新日時:{" "}
              {moment(fileData.LastModified).format("YYYY-MM-DD HH:mm:ss")}
            </div>
            <div style={{ width: "20%" }}>
              <Button color="primary" onClick={() => downloadCsv(fileData.Key)}>
                ダウンロード
              </Button>
            </div>
          </AvailableFiles>
        ))}
      </div>

      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  );
};

export default PaymentManagement;

const AvailableFiles = styled.div`
  display: flex;
  align-items: center;
`;

import { OrderType } from "./shop.entity";

export enum PaymentMethod {
  CASH = "cash",
  CARD = "card",
}

export enum OrderStatus {
  PREPARING = "preparing",
  READY = "ready",
  DONE = "done",
}

export interface AddressInfo {
  address1: string;

  address2: string;

  zipCode: string;

  phoneNumber: string;
}

export interface OrderChoice {
  choice_id: string;

  name: string;

  quantity: number;

  price: number;

  options?: OrderOption[];

  shop_display_name?: string;
}

export interface OrderOption {
  option_id: string;

  name: string;

  choices: OrderChoice[];
}

export interface OrderMenu {
  menu_id: string;

  name: string;

  shop_display_name?: string;

  quantity: number;

  price: number;

  // ユーザからのリクエストには含まれないため，バックエンドで計算して入れる
  price_all?: number;

  menu_type_id: string;

  category_id: string;

  remark?: string;

  options?: OrderOption[];
}

export interface Order {
  company_id: string;

  shop_id: string;

  user_id: string;

  price_all: number;

  order_id: string;

  coupon_id?: string;

  ordered_at: number;

  is_canceled: boolean;

  parent_order_id?: string;

  order_status: OrderStatus;

  is_paid: boolean;

  visit_at: number;

  order_menus: OrderMenu[];

  is_read: boolean;

  order_number: string;

  discount_amount?: number;

  tax_price: number;

  price: number;

  updated_at: number;

  is_print_ready: boolean;

  is_printed: boolean;

  order_type: OrderType;

  address_info?: AddressInfo;

  payment_method: PaymentMethod;

  phone_number: string;

  paid_amount: number;

  cancel_description?: string;

  refunded_amount?: number;

  nick_name?: string;

  delivery_fee_amount?: number;
}

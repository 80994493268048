import axiosBase from "axios";
import * as firebase from "firebase/app";
import "firebase/auth";

const getBaseURL = () => {
  if (process.env.REACT_APP_USE_LOCAL_API === "yes") {
    return "http://localhost:3030";
  }

  switch (process.env.REACT_APP_ENV) {
    case "development":
    case "staging":
      return "https://api.stag.payment.for.dinii.jp/v2";
    case "production":
      // return "https://api.payment.for.dinii.jp/v2";
      return "https://zdgelaxe1f.execute-api.ap-northeast-1.amazonaws.com/prod";
    default:
      throw new Error("invalid env");
  }
};

const request = axiosBase.create({
  baseURL: getBaseURL(),
});

request.interceptors.request.use(async config => {
  const user = firebase.auth().currentUser;
  if (!user) throw new Error("user not login");
  // eslint-disable-next-line no-param-reassign
  config.headers["x-auth-key"] = await user.getIdToken(true);
  return config;
});

interface DumpSummaryQuery {
  companyId: string;
  shopId: string;
  startDate: string;
  endDate: string;
  paymentMethod: string;
  payoutId?: string;
}

export const dumpSummaryRequest = async ({
  companyId,
  shopId,
  startDate,
  endDate,
  paymentMethod,
  payoutId,
}: DumpSummaryQuery): Promise<void> => {
  const response = await request.get(
    `companies/${companyId}/shops/${shopId}/`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
        payment_method: paymentMethod,
        payout_id: payoutId,
      },
    },
  );
  return response.data;
};

export const listCsv = async () => {
  const response = await request.get(`/dumps/list`);
  return response.data as {
    Contents: {
      Key: string;
      LastModified: string;
    }[];
  };
};

export const downloadCsv = async (key: string): Promise<void> => {
  const response = await request.get<string>(`/dumps?file_name=${key}`);
  try {
    saveData(response.data, key);
  } catch (exc) {
    console.log("Save Blob method failed with the following exception.");
    console.log(exc);
  }
  return;
};

const saveData = (function() {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.setAttribute("display", "none");
  return function(data: any, fileName: string) {
    const blob = new Blob([data], { type: "text/csv; charset=UTF-8" });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // eslint-disable-next-line no-unused-vars
  Theme,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { green, red } from "@material-ui/core/colors";
import DataTableRowComponent from "../../components/dataTableRowComponent";
import CreateMenuDialog from "./CreateMenuDialog";
import { Menu } from "../../../entity/menu.entity";
import * as request from "../../../plugins/requests";
import OptionDialog from "./OptionDialog/OptionDialog";
import { createMenuImageUrl, uploadMenuImage } from "../../../plugins/requests";
import { ShopSelection } from "../../components/shopSelection";
import moment from "moment-timezone";
import { useShops } from "../../../hooks/useShops";

const createObjectURL = (window.URL || window.webkitURL).createObjectURL;

const addRandUnix = (val: string): string => `${val}?${moment().unix()}`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    okCell: {
      backgroundColor: green.A100,
    },
    ngCell: {
      backgroundColor: red.A100,
    },
    form: {
      "& > *": {
        margin: theme.spacing(1),
        width: 200,
        height: 40,
      },
    },
    list: {
      maxHeight: "250px",
      width: "280px",
      overflow: "auto",
    },
  }),
);

const Menus: React.FC = () => {
  const classes = useStyles();

  const { companies, shops, selectedShop, setSelectedShop } = useShops();

  const [selectedMenu, setSelectedMenu] = useState(null as Menu | null);
  const [menus, setMenus] = useState([] as Menu[]);

  const categories = useCallback(() => selectedShop?.categories || [], [
    selectedShop,
  ]);

  const menuTypes = useCallback(() => selectedShop?.menu_types || [], [
    selectedShop,
  ]);

  // edit flags
  const [editFocusIndex, setEditFocusIndex] = useState(null as null | number);
  const [openCategoryEditorMenuId, setOpenCategoryEditorMenuId] = useState(
    null as string | null,
  );
  const [openMenuTypeEditorMenuId, setOpenMenuTypeEditorMenuId] = useState(
    null as string | null,
  );
  const [openUploaderDialog, setOpenUploaderDialog] = useState(false);

  // api response dialog
  const [dialogMessage, setDialogMessage] = useState(null as null | string);

  const [createMenuModalOpen, setCreateMenuModalOpen] = useState(false);
  const [optionDialogOpen, setOpitonDialogOpen] = useState(false);

  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  let fileUploaded: HTMLInputElement | null;

  const reloadMenu = async (menu: Menu) => {
    try {
      const fetchedMenu = await request.getMenu(
        menu.company_id,
        menu.shop_id,
        menu.menu_id,
      );
      setMenus(
        menus.map(existingMenu =>
          existingMenu.menu_id === fetchedMenu.menu_id
            ? fetchedMenu
            : existingMenu,
        ),
      );
      setSelectedMenu(fetchedMenu);
    } catch (err) {
      setDialogMessage(JSON.stringify(err, null, 2));
    }
  };

  const updateMenu = <K extends keyof Menu>(
    menu: Menu,
    key: K,
    value: Menu[K],
  ) => {
    request
      .updateMenu(menu.company_id, menu.shop_id, menu.menu_id, { [key]: value })
      .then(updatedMenu => {
        setMenus(
          menus.map(existingMenu =>
            existingMenu.menu_id === updatedMenu.menu_id
              ? updatedMenu
              : existingMenu,
          ),
        );
        setDialogMessage("update success");
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
    setEditFocusIndex(null);
  };

  const removeMenuProperty = (
    menu: Menu,
    key: "shop_display_name" | "description" | "image_url",
  ) => {
    request
      .removeMenuProperty(menu.company_id, menu.shop_id, menu.menu_id, key)
      .then(updatedMenu => {
        setMenus(
          menus.map(existingMenu =>
            existingMenu.menu_id === updatedMenu.menu_id
              ? updatedMenu
              : existingMenu,
          ),
        );
        setDialogMessage("update success");
      })
      .catch(err => setDialogMessage(JSON.stringify(err, null, 2)));
    setEditFocusIndex(null);
  };

  const handleChangeFile = (e: any, menuId: string) => {
    const files = e.target.files;
    const selectedImageUrl =
      files.length === 0 ? "" : createObjectURL(files[0]);
    setSelectedImageUrl(selectedImageUrl);
    console.log("test:", fileUploaded);
  };

  const uploadImage = async (menu: Menu) => {
    const url = await createMenuImageUrl(
      menu.company_id,
      menu.shop_id,
      menu.menu_id,
    );

    try {
      const fileData = fileUploaded!.files![0];
      await uploadMenuImage(fileData, `${menu.menu_id}.jpg`, url);

      const imageUrl = url.split("?")[0];
      updateMenu(menu, "image_url", addRandUnix(imageUrl));
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const selectShop = (shopId: string) => {
    const shop = shops.find(_shop => _shop.shop_id === shopId);
    if (!shop) return;
    const company = companies.find(
      _company => _company.company_id === shop.company_id,
    );
    if (!company) return;
    setMenus([]);
    setSelectedShop(shop);
  };

  const openOptionDialog = (menu: Menu) => {
    setSelectedMenu(menu);
    setOpitonDialogOpen(true);
  };

  useEffect(() => {
    if (!selectedShop) return;
    request
      .getMenus(selectedShop.company_id, selectedShop.shop_id)
      .then(menus => {
        setMenus(prevState => [...prevState, ...menus]);
      });
  }, [selectedShop]);

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        style={{ width: "70vw", marginBottom: "30px" }}
        onClick={() => setCreateMenuModalOpen(true)}
      >
        メニューを作成する
      </Button>

      <ShopSelection
        selectedShop={selectedShop}
        shops={shops}
        selectShop={selectShop}
      />

      <Paper>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "20px" }} align="left">
                Company ID
              </TableCell>
              <TableCell style={{ width: "20px" }} align="right">
                Shop ID
              </TableCell>
              <TableCell style={{ width: "20px" }} align="right">
                Shop Name
              </TableCell>
              <TableCell align="right">メニュー名</TableCell>
              <TableCell align="right">店舗側表示名</TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell align="right">カテゴリ名</TableCell>
              <TableCell align="right">メニュータイプ名</TableCell>
              <TableCell align="right">値段</TableCell>
              <TableCell align="right">在庫上限</TableCell>
              <TableCell align="right">売り切れ</TableCell>
              <TableCell align="right">表示非表示</TableCell>
              <TableCell align="right">表示順(低いほど上に表示)</TableCell>
              <TableCell align="right">オプション設定</TableCell>
              <TableCell align="right">画像URL</TableCell>
              <TableCell align="right">画像アップロード</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {menus.map((menu, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {menu.company_id}
                </TableCell>
                <TableCell align="right">{menu.shop_id}</TableCell>
                <TableCell align="right">{selectedShop?.name || ""}</TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: menu.name,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      updateMenu(menu, "name", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: menu.shop_display_name,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      updateMenu(menu, "shop_display_name", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: menu.description,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      updateMenu(menu, "description", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                </TableCell>

                <TableCell align="right">
                  <p>
                    <span>
                      {categories().find(
                        cat => cat.category_id === menu.category_id,
                      )?.name || "なし"}
                    </span>
                    <Button
                      color="primary"
                      onClick={() => setOpenCategoryEditorMenuId(menu.menu_id)}
                    >
                      <EditIcon style={{ width: "15px" }} />
                    </Button>
                  </p>

                  <Dialog
                    open={openCategoryEditorMenuId === menu.menu_id}
                    onClose={() => setOpenCategoryEditorMenuId(null)}
                  >
                    <DialogContent>
                      <List dense className={classes.list}>
                        {categories().map((category, categoryIndex) => (
                          <ListItem key={categoryIndex} button>
                            <ListItemText
                              id={`${category.category_id}`}
                              primary={category.name}
                            />
                            <ListItemSecondaryAction>
                              <Checkbox
                                edge="end"
                                onChange={() =>
                                  updateMenu(
                                    menu,
                                    "category_id",
                                    category.category_id,
                                  )
                                }
                                checked={
                                  menu.category_id === category.category_id
                                }
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </DialogContent>
                  </Dialog>
                </TableCell>

                <TableCell align="right">
                  <p>
                    <span>
                      {menuTypes().find(
                        mt => mt.menu_type_id === menu.menu_type_id,
                      )?.name || "なし"}
                    </span>
                    <Button
                      color="primary"
                      onClick={() => setOpenMenuTypeEditorMenuId(menu.menu_id)}
                    >
                      <EditIcon style={{ width: "15px" }} />
                    </Button>
                  </p>

                  <Dialog
                    open={openMenuTypeEditorMenuId === menu.menu_id}
                    onClose={() => setOpenMenuTypeEditorMenuId(null)}
                  >
                    <DialogContent>
                      <List dense className={classes.list}>
                        {menuTypes().map((menuType, menuTypeIndex) => (
                          <ListItem key={menuTypeIndex} button>
                            <ListItemText
                              id={`${menuType.menu_type_id}`}
                              primary={menuType.name}
                            />
                            <ListItemSecondaryAction>
                              <Checkbox
                                edge="end"
                                onChange={() =>
                                  updateMenu(
                                    menu,
                                    "menu_type_id",
                                    menuType.menu_type_id,
                                  )
                                }
                                checked={
                                  menu.menu_type_id === menuType.menu_type_id
                                }
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </DialogContent>
                  </Dialog>
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: menu.price,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      updateMenu(menu, "price", Number(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "number",
                  })}
                </TableCell>
                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: menu.max_num,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      updateMenu(menu, "max_num", Number(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "number",
                  })}
                </TableCell>
                <TableCell align="right">
                  <Switch
                    required
                    checked={menu.is_soldout}
                    onChange={() =>
                      updateMenu(menu, "is_soldout", !menu.is_soldout)
                    }
                    value="checkedA"
                  />
                </TableCell>
                <TableCell align="right">
                  <Switch
                    required
                    checked={menu.is_display}
                    onChange={() =>
                      updateMenu(menu, "is_display", !menu.is_display)
                    }
                  />
                </TableCell>
                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: menu.priority,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      updateMenu(menu, "priority", Number(val));
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "number",
                  })}
                </TableCell>

                <TableCell align="center">
                  <Button onClick={() => openOptionDialog(menu)}>
                    オプション設定
                  </Button>
                </TableCell>

                <TableCell align="right">
                  {DataTableRowComponent({
                    defaultValue: menu.image_url,
                    startEdit: () => setEditFocusIndex(index),
                    cancelEdit: () => setEditFocusIndex(null),
                    applyChange: (val: any) => {
                      updateMenu(menu, "image_url", val);
                    },
                    formClassName: classes.form,
                    isShowEditForm: editFocusIndex === index,
                    dataType: "string",
                  })}
                  {menu.image_url && menu.image_url !== "" && (
                    <div>
                      <img src={menu.image_url} width={50} height={50} alt="" />
                    </div>
                  )}
                  <Button onClick={() => removeMenuProperty(menu, "image_url")}>
                    画像削除
                  </Button>
                </TableCell>

                <TableCell>
                  <Button
                    onClick={() => {
                      setSelectedMenu(menu);
                      setOpenUploaderDialog(true);
                    }}
                  >
                    アップロード
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Dialog
        open={dialogMessage !== null}
        onClose={() => setDialogMessage(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Result</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Dialog>

      {openUploaderDialog && selectedMenu && (
        <Dialog
          open={openUploaderDialog}
          onClose={() => setOpenUploaderDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">画像アップローダー</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <input
                ref={ref => (fileUploaded = ref)}
                type="file"
                id="menu-image"
                onChange={e => handleChangeFile(e, selectedMenu.menu_id)}
              />
              <img src={selectedImageUrl} alt="" />
              <button onClick={() => uploadImage(selectedMenu)}>
                アップロード
              </button>
            </DialogContentText>
          </DialogContent>
          <DialogActions />
        </Dialog>
      )}

      {selectedShop && (
        <CreateMenuDialog
          shop={selectedShop}
          isOpen={createMenuModalOpen}
          onClose={() => setCreateMenuModalOpen(false)}
        />
      )}

      {selectedShop && selectedMenu && (
        <OptionDialog
          reloadMenu={() => reloadMenu(selectedMenu)}
          menu={selectedMenu}
          shop={selectedShop}
          isOpen={optionDialogOpen}
          onClose={() => {
            setSelectedMenu(null);
            setOpitonDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default Menus;
